<template>
  <modal-wrapper :title="title" :isVisible="modelValue" :userCanEdit="userCanEdit" class="modal-contrato"
    @cerrar-modal="cerrarModal" @guardar-modal="guardarModal">
    <template #top-bar-buttons>
      <button-options v-if="esEdicion && userCanEdit" :options="menuOptions" />
    </template>
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.contrato.$error" />
    <div class="formgrid grid my-5" id="modal-contrato">
      <div class="col-6">
        <p-float-label label="Código" required>
          <input-text v-model="v$.contrato.IdT.$model" type="text" :disabled="!userCanEdit" :class="{
            'p-invalid': v$?.contrato?.IdT?.$error,
          }" />
        </p-float-label>
      </div>
      <div class="col-6">
        <p-float-label label="Tipo de contrato" required>
          <dropdown-element @change="tipoContratoChange" v-model="v$.contrato.IdTipoContrato.$model" :loading="isLoading"
            :options="listaTipoContrato" optionLabel="Descripcion" optionValue="Id" :disabled="!userCanEdit" :class="{
              'p-invalid': v$?.contrato?.IdTipoContrato?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Fecha inicio">
          <calendar-element @date-select="setMinDate" @clear-click="minDate = null"
            v-model="v$.contrato.FechaInicioContrato.$model" :showButtonBar="true" :manualInput="false"
            dateFormat="yy-mm-dd" :disabled="!userCanEdit" :showIcon="true" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Fecha fin">
          <calendar-element v-model="v$.contrato.FechaFinContrato.$model" dateFormat="yy-mm-dd" :showButtonBar="true"
            :manualInput="false" :showIcon="true" :disabled="(isDisabled && !esEdicion) || !userCanEdit"
            :minDate="minDate" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5 relative">
        <button-element v-if="verBotonCertificado" label="Certificado FE" class="absolute"
          style="top: -26px; right: 10px; font-size: 10px; padding: 4px;" @click="buscarCertificadoHandler" />
        <p-float-label label="Producto" required>
          <dropdown-element @change="buscarAtributos" v-model="v$.contrato.IdProducto.$model" :loading="isLoading"
            :options="listaProducto" optionLabel="Descripcion" optionValue="Id" :disabled="!userCanEdit" :class="{
              'p-invalid': v$?.contrato?.IdProducto?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-3 mt-5">
        <p-float-label label="Valor">
          <currency-input v-model="v$.contrato.Valor.$model" :disabled="!userCanEdit" />
        </p-float-label>
      </div>
      <div class="col-3 mt-5">
        <p-float-label label="Moneda" :required="!tipoContratoEsAmparado">
          <dropdown-element v-model="v$.contrato.IdMoneda.$model" :loading="isLoading" :options="listaMoneda"
            :optionLabel="(data) => `${data.Idt}-${data.Descripcion}`" optionValue="Id" showClear :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contrato?.IdMoneda?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-4 mt-5">
        <p-float-label label="Tipo de pago" :required="!tipoContratoEsAmparado">
          <dropdown-element v-model="v$.contrato.IdTipoPago.$model" :loading="isLoading" :options="listaTipoPago"
            optionLabel="Descripcion" optionValue="Id" showClear :disabled="!userCanEdit" :class="{
              'p-invalid': v$?.contrato?.IdTipoPago?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-4 mt-5">
        <uploader-url label="Link PSE" :url="contrato.LinkPSE" :validation="esLink">
          <input-text v-model="v$.contrato.LinkPSE.$model" type="text" :disabled="!userCanEdit" :class="{
            'p-invalid': v$?.contrato?.LinkPSE?.$invalid,
          }" />
        </uploader-url>
      </div>
      <div class="col-4 mt-5 field-checkbox">
        <check-box v-model="v$.contrato.FacturacionAnticipada.$model" :binary="true" :disabled="!userCanEdit"
          id="anticipada" />
        <label for="anticipada">Fact. anticipada</label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Siguiente factura" required>
          <calendar-element v-model="v$.contrato.FechaSiguienteFactura.$model" :manualInput="false" dateFormat="yy-mm-dd"
            :showButtonBar="true" :showIcon="true" :minDate="minDate" :disabled="!userCanEdit" :class="{
              'p-invalid': v$?.contrato?.FechaSiguienteFactura?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Estado" required>
          <dropdown-element v-model="v$.contrato.IdEstado.$model" :loading="isLoading" :options="listaEstados"
            optionLabel="Descripcion" optionValue="Id" :disabled="!userCanEdit" :class="{
              'p-invalid': v$?.contrato?.IdEstado?.$error,
            }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Sede" required>
          <dropdown-element v-model="v$.contrato.IdClienteSede.$model" :loading="isLoading" :options="listaSede"
            :optionLabel="(data) =>
                `${data.Direccion}${data.EsPrincipal ? ' - Principal' : ''}`
              " optionValue="Id" :disabled="!userCanEdit" :class="{
    'p-invalid': v$?.contrato?.IdClienteSede?.$error,
  }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Contacto" required>
          <dropdown-element v-model="v$.contrato.IdContacto.$model" :loading="isLoading" :options="listaContacto"
            :optionLabel="(data) =>
                `${data.Nombre1} ${data.Apellido1}${data.Principal ? ' - Principal' : ''
                }`
              " optionValue="Id" :disabled="!userCanEdit" :class="{
    'p-invalid': v$?.contrato?.IdContacto?.$error,
  }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Base de datos" required>
          <input-text v-model="v$.contrato.BaseDatos.$model" type="text" :disabled="!userCanEdit" :class="{
            'p-invalid': v$?.contrato?.BaseDatos?.$error,
          }" />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Servidor" required>
          <input-text v-model="v$.contrato.Servidor.$model" type="text" :disabled="!userCanEdit" :class="{
            'p-invalid': v$?.contrato?.Servidor?.$error,
          }" />
        </p-float-label>
      </div>
      <div v-if="hayAtributos" class="col-12 mt-5">
        <data-table id="tabla-atributos-contrato" :value="contrato.atributos" editMode="cell" responsiveLayout="scroll"
          @cell-edit-complete="onCellEditComplete">
          <column-element field="CodigoDescripcion" header="Atributo">
            <template #body="{ data, field }">
              <span :class="{ 'input-required': data.Requerido }">
                {{ data[field] }}
              </span>
            </template>
          </column-element>
          <column-element field="Valor" header="Valor" style="width: 50%">
            <template #body="{ data, field, index }">
              <input-text v-model="data[field]" class="p-inputtext-sm" style="width: 100%; height: 32px"
                placeholder="Ingresa un valor" :disabled="!userCanEdit" :class="{
                  'p-invalid':
                    v$?.contrato?.atributos?.$each?.$response?.$data?.[index]
                      ?.Valor?.$error && validarAtributos,
                }" />
            </template>
          </column-element>
        </data-table>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Observaciones">
          <text-area v-model="v$.contrato.Observaciones.$model" rows="2" :disabled="!userCanEdit" />
        </p-float-label>
      </div>
      <div class="col-12 mt-2">
        <label class="form-label mb-1">Notificación</label>
        <editor-element v-model="v$.contrato.Notificacion.$model" :readonly="!userCanEdit" editorStyle="height: 100px" />
        <span v-if="v$?.contrato?.Notificacion?.$error" class="p-error mt-1 ml-2">
          La cantidad máxima de caracteres es {{ notificacionLength }}
        </span>
      </div>
    </div>
    <certificado-modal v-model:datos="certificado" v-model="abrirModalCertificado" :idContrato="contrato.Id"
      :disabled="!userCanEditCertificados" />
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { dateParser } from "@/helpers/dateParser";
import UploaderUrl from "@/components/UploaderUrl.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CertificadoModal from "./CertificadoModal.vue";
import { buscarCertificado } from "@/services/certificados.js";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  requiredIf,
  url,
  maxLength,
} from "@vuelidate/validators";

export default {
  components: { UploaderUrl, CurrencyInput, CertificadoModal },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
    datos: Object,
  },
  data() {
    return {
      contrato: {
        FacturacionAnticipada: false,
        IdEstado: 1,
        IdMoneda: 1,
        IdContacto: null,
        IdClienteSede: null,
        atributos: [],
      },
      menuOptions: [
        {
          label: "Duplicar",
          icon: "pi pi-clone",
          command: () => {
            this.$emit("duplicarContrato", this.contrato.Id);
          },
        },
      ],
      minDate: null,
      validarAtributos: false,
      notificacionLength: 1024,
      certificado: null,
      abrirModalCertificado: false,
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados",
      listaRegimen: "cbx/getRegimen",
      listaTipoDocumento: "cbx/getTipoDocumento",
      listaPais: "cbx/getPais",
      listaCategoria: "cbx/getCategoria",
      listaCargo: "cbx/getCargo",
      listaSede: "clientes/getSedes",
      listaContacto: "clientes/getContactos",
      listaProducto: "cbx/getProducto",
      listaTipoPago: "cbx/getTipoPago",
      listaTipoContrato: "cbx/getTipoContrato",
      listaMoneda: "cbx/getMoneda",
      contactoPrincipal: "clientes/getContactoPrincipal",
      sedePrincipal: "clientes/getSedePrincipal",
      userCanEdit: "auth/userCanEdit",
      userCanEditCertificados: "auth/userCanEditCertificados",
      userCanReadCertificados: "auth/userCanReadCertificados",
    }),
    title() {
      if (this.esEdicion) return "Editar contrato";
      return "Crear contrato";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    esLink() {
      if (!this.contrato.LinkPSE) return false;
      if (this.v$?.contrato?.LinkPSE?.$invalid) return false;
      return true;
    },
    isDisabled() {
      if (this.minDate) {
        return false;
      }
      return true;
    },
    hayAtributos() {
      if (this.contrato.atributos.length === 0) {
        return false;
      }
      return true;
    },
    tipoContratoActual() {
      return this.listaTipoContrato.find(
        (e) => e.Id == this.contrato?.IdTipoContrato
      );
    },
    tipoContratoEsAmparado() {
      return this.tipoContratoActual?.Descripcion == "AMPARADO";
    },
    selectedProduct() {
      return this.listaProducto.find(
        (e) => e.Id == this.contrato?.IdProducto
      );
    },
    esProductoErp() {
      return this.selectedProduct?.IdT?.includes("ERP") ?? false;
    },
    verBotonCertificado() {
      return this.esEdicion && this.userCanReadCertificados && this.esProductoErp;
    },
  },
  methods: {
    ...mapActions({
      listarProductoAtributo: "productos/listarProductoAtributo",
    }),
    async cerrarModal() {
      const formHasChanged = this.v$.contrato.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      this.validarAtributos = true;
      const isFormCorrect = await this.v$.contrato.$validate();
      if (!isFormCorrect) return;
      this.datesToJSON();
      if (!this.esEdicion) this.$emit("crearContrato", this.contrato);
      else this.$emit("editarContrato", this.contrato);
    },
    resetModal() {
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
      this.minDate = null;
      this.validarAtributos = false;
      this.contrato = {
        FacturacionAnticipada: false,
        IdEstado: 1,
        IdMoneda: 1,
        IdContacto: this.contactoPrincipal?.Id ?? null,
        IdClienteSede: this.sedePrincipal?.Id ?? null,
        atributos: [],
      };
    },
    datesToJSON() {
      this.contrato.FechaInicioContrato = dateParser(
        this.contrato.FechaInicioContrato
      );
      this.contrato.FechaFinContrato = dateParser(
        this.contrato.FechaFinContrato
      );
      this.contrato.FechaSiguienteFactura = dateParser(
        this.contrato.FechaSiguienteFactura
      );
    },
    setMinDate(value) {
      this.minDate = value;
      this.contrato.FechaFinContrato = null;
      this.contrato.FechaSiguienteFactura = null;
    },
    tipoContratoChange({ value }) {
      if (this.listaTipoContrato[value]?.FacturacionAnticipada) {
        this.contrato.FacturacionAnticipada = true;
      } else {
        this.contrato.FacturacionAnticipada = false;
      }
    },
    async buscarAtributos({ value }) {
      const data = await this.listarProductoAtributo(value);
      if (data) {
        const formatData = data.map((e) => {
          return {
            IdAtributo: e.IdAtributo,
            CodigoDescripcion: e.CodigoDescripcion,
            Requerido: e.Requerido,
            Valor: null,
          };
        });
        this.contrato.atributos = formatData;
      }
    },
    atributoRequerido(value, object) {
      return object.Requerido;
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;

      if (newValue) data[field] = newValue;
      else event.preventDefault();
    },
    async buscarCertificadoHandler() {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await buscarCertificado(this.contrato.Id);
      this.$store.commit("setIsLoading", false);
      if (status == 200 || status == 404) {
        this.certificado = status == 200 ? data : null;
        this.abrirModalCertificado = true;
      } else this.$alertStatus(status, data);
    },
  },
  validations() {
    return {
      contrato: {
        IdClienteSede: { required },
        IdContacto: { required },
        IdProducto: { required },
        IdEstado: { required },
        IdMoneda: { required: requiredIf(!this.tipoContratoEsAmparado) },
        IdTipoContrato: { required },
        IdTipoPago: { required: requiredIf(!this.tipoContratoEsAmparado) },
        IdT: { required },
        Servidor: { required },
        BaseDatos: { required },
        FechaSiguienteFactura: { required },
        LinkPSE: { url },
        Valor: {},
        Observaciones: {},
        Notificacion: { maxLength: maxLength(this.notificacionLength) },
        FacturacionAnticipada: {},
        FechaInicioContrato: {},
        FechaFinContrato: {},
        atributos: {
          $each: helpers.forEach({
            Valor: {
              required: requiredIf(this.atributoRequerido),
            },
          }),
        },
      },
    };
  },
  watch: {
    datos: {
      handler() {
        if (this.datos === null) return;
        this.contrato = { ...this.datos };
        this.minDate = new Date(this.contrato.FechaInicioContrato);
        this.minDate.setDate(this.minDate.getDate() + 1);
      },
    },
    contactoPrincipal: {
      handler() {
        this.contrato.IdContacto = this.contactoPrincipal?.Id ?? null;
      },
    },
    sedePrincipal: {
      handler() {
        this.contrato.IdClienteSede = this.sedePrincipal?.Id ?? null;
      },
    },
  },
};
</script>

<style lang="scss">
.modal-container.modal-contrato {
  width: 700px;
}
</style>
