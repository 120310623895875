<template>
  <div id="layout" :class="{ 'sidebar-open': sidebarVisible }">
    <top-bar />
    <left-bar />
    <main id="layout-content">
      <router-view />
    </main>
  </div>
</template>

<script>
import TopBar from "@/components/layout/TopBar.vue";
import LeftBar from "@/components/layout/LeftBar.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    TopBar,
    LeftBar,
  },
  async created() {
    this.$store.commit("cbx/setIsLoading", true);
    // Datos default para comboboxes modales/formularios
    await this.getGaranttoBaseDatos();
    await this.getGaranttoCiudades({ pais: this.dbPais.IdT });
    await this.getEstados();
    await this.getRegimen();
    await this.getTipoDocumento();
    await this.getListaCiudad();
    await this.getListaPais();
    await this.getCategoria();
    await this.getCargo();
    await this.getProducto();
    await this.getTipoPago();
    await this.getTipoContrato();
    await this.getMoneda();
    this.$store.commit("cbx/setIsLoading", false);
  },
  methods: {
    ...mapActions({
      getEstados: "cbx/getCbxEstados",
      getRegimen: "cbx/getCbxRegimen",
      getTipoDocumento: "cbx/getCbxTipoDocumento",
      getListaCiudad: "cbx/getCbxCiudad",
      getListaPais: "cbx/getCbxPais",
      getCategoria: "cbx/getCbxCategoria",
      getCargo: "cbx/getCbxCargo",
      getProducto: "cbx/getCbxProducto",
      getTipoPago: "cbx/getCbxTipoPago",
      getTipoContrato: "cbx/getCbxTipoContrato",
      getMoneda: "cbx/getCbxMoneda",
      getGaranttoBaseDatos: 'cbx/getCbxGaranttoBaseDatosMaestro',
      getGaranttoCiudades: 'cbx/getCbxGaranttoCiudadesMaestro',
    }),
  },
  computed: {
    ...mapState(["sidebarVisible"]),
    ...mapGetters({
      dbPais:"gpais/getPais",
    }),
  },
  watch: {
    async dbPais(newValue) {
      await this.getGaranttoCiudades({ pais: newValue.IdT })
    }
  }
};
</script>
