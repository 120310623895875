<template>
    <input-number
      :modelValue="value"
      prefix="$"
      :class="{
        'p-invalid': invalid,
      }"
      inputClass="text-right"
      locale="de-DE"
      :disabled="disabled"
      :minFractionDigits="2"
      @input="(e) => $emit('update:modelValue', e.value)"
    />
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: [String, Number],
      },
      invalid: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      value() {
        const parsed = parseFloat(this.modelValue);
        return isNaN(parsed) ? null : parsed;
      },
    },
  };
  </script>
  