import axios from "./config";

export const listarProducto = async (params) => {
  const { data, status } = await axios
    .get("/producto", { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarProducto = async (id) => {
  const { data, status } = await axios
    .get(`/producto/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearProducto = async (producto) => {
  const { data, status } = await axios
    .post("/producto", producto)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarProducto = async (producto) => {
  const { data, status } = await axios
    .put(`/producto/${producto.Id}`, producto)
    .catch((e) => e.response);
  return { data, status };
};

export const productoAtributoListar = async (productoId) => {
  const { data, status } = await axios
    .get(`/producto/${productoId}/atributo`)
    .catch((e) => e.response);
  return { data, status };
};

export const productoAtributoCrear = async (productoId, atributo) => {
  const { data, status } = await axios
    .post(`/producto/${productoId}/atributo`, atributo)
    .catch((e) => e.response);
  return { data, status };
};

export const productoAtributoActualizar = async (
  productoId,
  atributoId,
  atributoNuevo
) => {
  const { data, status } = await axios
    .put(`/producto/${productoId}/atributo/${atributoId}`, atributoNuevo)
    .catch((e) => e.response);
  return { data, status };
};

export const productoAtributoEliminar = async (productoId, atributoId) => {
  const { data, status } = await axios
    .delete(`/producto/${productoId}/atributo/${atributoId}`)
    .catch((e) => e.response);
  return { data, status };
};
