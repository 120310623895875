<template>
  <dialog-element
    :modal="true"
    :visible="isVisible"
    :closable="false"
    :draggable="false"
    class="modal-container"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center w-full">
        <div class="flex align-items-center">
          <button-element
            v-if="esMovil"
            icon="pi pi-arrow-left"
            class="mr-2"
            @click="$emit('cerrarModal')"
          />
          <span>{{ title }}</span>
        </div>
        <div>
          <slot name="top-bar-buttons" />
          <button-element
            v-if="showSaveButton"
            icon="pi pi-save"
            class="mx-2"
            :disabled="!userCanEdit"
            @click="guardar"
          />
          <button-element
            v-if="!esMovil"
            icon="pi pi-times"
            @click="$emit('cerrarModal')"
          />
        </div>
      </div>
    </template>
    <!-- Formulario -->
    <slot />
  </dialog-element>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: String,
    isVisible: Boolean,
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    userCanEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["esMovil"]),
  },
  methods: {
    guardar() {
      if (!this.userCanEdit) return;

      this.$emit("guardarModal");
    },
  },
};
</script>
