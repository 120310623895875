<template>
  <div>
    <toolbar-element id="top-bar" > 
      <!-- Logo -->
      <template #start>
        <div id="burger-menu">
          <i @click="$store.commit('setSidebarVisible')" class="pi pi-bars"></i>
        </div>
        <img class="w-7rem" src="../../assets/Logo-appinglab.png" alt="Logo Garantto" />
      </template>

      <template #end>
        <!-- Selector de país -->
        <dropdown-element
          v-if="esRutaGarantto"
          v-model="pais"
          :options="listaGaranttoPaises"
          :loading="isLoading"
          optionLabel="Descripcion"
          optionValue="IdT"
          @change="onChangeBaseDatosPais"
          class="mr-2"
        />
        <!-- Boton notificaciones -->
        <button-element id="btn-notificaciones" @click="(e) => $refs.notificationsMenu.toggle(e)" icon="pi pi-bell"
          class="p-button-rounded mr-2 p-3" />
        <menu-element id="notifications-menu" ref="notificationsMenu" :model="notifications" :popup="true">
          <template #item="{ item }">
            <div class="notifications-menu-items border-bottom-1 border-gray-300">
              <span class="notifications-menu-item text-lg font-semibold">
                {{ item.label }}
              </span>
              <span class="notifications-menu-item text-sm text-gray-600">
                {{ item.date }}
              </span>
            </div>
          </template>
        </menu-element>
        <!-- Boton usuario -->
        <button-element id="btn-usario" icon="pi pi-user" class="p-button-rounded p-3" @click="togglePopupUser" />
        <menu-element ref="userMenu" :model="itemsPopupUser" :popup="true" />
      </template>
    </toolbar-element>
    <Dialog v-model:visible="verAlertaPais" modal header="Cambio de país" :style="{ width: '30rem' }">
      <p class="m-0">
        ¡Estás realizando el cambio de datos a otro país!
      </p>
      <template #footer>
          <Button label="Ok" icon="pi pi-check" @click="asignarBaseDatosPais()" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { setItem } from '@/helpers/storageHandler'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
  components: { Dialog, Button },
  data() {
    return {
      pais: null,
      verAlertaPais: false,
      notifications: [
        /* {
          label: "Cotización aprobada",
          description: "22-0037 Sky motion",
          date: "hace un mes",
        },
        {
          label: "Cotización aprobada",
          description: "22-0037 Sky motion",
          date: "hace un mes",
        },
        {
          label: "Garantia rechazada",
          description: "21-0116 Nestor Peña Garcia",
          date: "hace 8 meses",
        }, */
      ],
      itemsPopupUser: [
        {
          label: "Perfil",
          icon: "pi pi-fw pi-user",
        },
        {
          label: "Cerrar sesión",
          icon: "pi pi-fw pi-sign-out",
          command: async () => {
            this.logout();
          },
        },
      ],
    };
  },
  mounted() {
    this.pais = this.dbPais?.IdT
  },
  computed: {
    ...mapGetters({
      dbPais:"gpais/getPais",
      listaGaranttoPaises: "cbx/getGaranttoBasedatosMaestro",
    }),
    ...mapState("cbx", ["isLoading"]),
    esRutaGarantto() {
      return this.$route.name.startsWith('garantto-');
    }
  },
  methods: {
    onChangeBaseDatosPais({ value }) {
      this.pais = value
      this.verAlertaPais = true
    },
    async asignarBaseDatosPais() {
      const datosPais = this.listaGaranttoPaises.find(pais => pais.IdT === this.pais)
      this.$store.commit("gpais/setPais", datosPais)
      setItem('gBaseDatos', datosPais)
      this.verAlertaPais = false
    },
    toggleNotifications(event) {
      this.$refs.notificationsMenu.toggle(event);
    },
    togglePopupUser(event) {
      this.$refs.userMenu.toggle(event);
    },
    logout() {
      this.$store.commit("setIsLoading", true);
      this.$store.commit("auth/logout", "authToken");
      location.replace("/login");
    },
  },
  watch: {
    async dbPais(newValue) {
      const routeName = this.$route.name
      await this.$router.push({ name: routeName, params: { dbPais: newValue?.IdT.toLowerCase() } })
    }
  }
};
</script>
