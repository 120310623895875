<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de marcas</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEditGarantto"
        @click="abrirModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :totalRecords="total"
    :empty="emptyMsg"
    :sortable="true"
    :buttonDelete="userCanEditGarantto"
    @editar="preEditar"
    @generatePage="listar"
    @eliminar="(data) => eliminar(data.Id)"
  >
    <template #IdEstado="{ value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: value == 1 ? '#c8e6c9' : '#e1e1e1' }"
      >
        {{ value == 1 ? "Habilitado" : "Inhabilitado" }}
      </span>
    </template>
  </table-data-lazy>
  <!-- Modal -->
  <g-marcas-modal
    v-model="abrirModal"
    v-model:datos="marca"
    @crear="preEditar"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import GMarcasModal from "../components/GMarcasModal.vue";
import { buscarMarca } from "../services/gmarcas.js";

export default {
  components: { TableDataLazy, GMarcasModal },
  data() {
    return {
      marca: {},
      columns: [],
      abrirModal: false,
      emptyMsg: "No se encontraron marcas...",
    };
  },
  computed: {
    ...mapGetters({
      lista: "gmarcas/getLista",
      total: "gmarcas/getTotal",
      userCanEditGarantto: "auth/userCanEditGarantto",
      dbPais:"gpais/getPais",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
      { field: "UltimaRevision", header: "Fecha últ. revisión" },
      { field: "IdEstado", header: "Estado" },
      { field: "Comentarios", header: "Comentarios" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "gmarcas/listar",
      eliminar: "gmarcas/eliminar",
    }),
    async preEditar(item) {
      if (item) {
        this.$store.commit("setIsLoading", true);
        const { data, status } = await buscarMarca(item.Id);
        this.$store.commit("setIsLoading", false);
        if (status == 200) {
          this.marca = data;
          this.abrirModal = true;
        } else this.$alertStatus(status, data);
      }
    },
  },
  watch: {
    dbPais() {
      this.listar()
    }
  }
};
</script>
