<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEditGarantto"
    style="width: 1000px"
    class="gmarcas-modal"
    position="top"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 my-3" v-if="v$.form.$error" />
    <div class="max-content">
      <tab-view>
        <tab-panel header="Datos básicos">
          <div class="formgrid grid my-5">
            <div class="col-12">
              <p-float-label label="Código" required>
                <input-text
                  type="text"
                  v-model="v$.form.IdT.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdT?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-12 mt-5">
              <p-float-label label="Descripción">
                <input-text
                  type="text"
                  v-model="v$.form.Descripcion.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Descripcion?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-12 mt-5">
              <file-uploader
                  label="Imagen"
                  :value="this.form.Foto"
                  :disabled="!userCanEditGarantto"
                  @clean-file="onCleanFile"
                  @change-file="onChangeFile"
                />
            </div>
            <div class="col-12 mt-5">
              <p-float-label label="Estado" required>
                <dropdown-element
                  v-model="v$.form.IdEstado.$model"
                  :loading="isLoading"
                  :options="listaEstados"
                  optionLabel="Descripcion"
                  optionValue="Id"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdEstado?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-12 mt-5">
              <uploader-url
                label="Ruta información"
                :url="form.RutaInformacion"
                :validation="esLink"
              >
                <input-text
                  v-model="v$.form.RutaInformacion.$model"
                  type="text"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.RutaInformacion?.$error,
                  }"
                />
              </uploader-url>
            </div>
            <div class="col-12 mt-5">
              <p-float-label label="Fecha últ. revisión">
                <calendar-element
                  v-model="v$.form.UltimaRevision.$model"
                  dateFormat="yy-mm-dd"
                  :showButtonBar="true"
                  :manualInput="false"
                  :showIcon="true"
                  :disabled="!userCanEditGarantto"
                />
              </p-float-label>
            </div>
            <div class="col-12 mt-5">
              <p-float-label label="Comentarios">
                <text-area
                  v-model="v$.form.Comentarios.$model"
                  rows="2"
                  autoResize
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Comentarios?.$error,
                  }"
                />
              </p-float-label>
            </div>
          </div>
        </tab-panel>
        <tab-panel v-if="esEdicion" header="Categorías y productos">
          <table-crud
            :lista="form.MarcaCategoria"
            :columns="columnsMarcaCategoria"
            :showHeader="false"
            :showEditButton="false"
            :showAddButton="userCanEditGarantto"
            :showDeleteButton="userCanEditGarantto"
            emptyMessage="No hay registros..."
            @crear="
              form.MarcaCategoria.push({ Categoria: null, IdG_Producto: null })
            "
            @eliminar="(data, i) => form.MarcaCategoria.splice(i, 1)"
          >
            <template #Categoria="{ data, index }">
              <input-text
                v-model="data['Categoria']"
                placeholder="Ingresar categoría"
                :disabled="!userCanEditGarantto"
                :class="{
                  'p-invalid':
                    v$?.form?.MarcaCategoria?.$each?.$response?.$data?.[index]
                      ?.Categoria?.$error && v$?.$dirty,
                }"
              />
            </template>
            <template #IdG_Producto="{ data, index }">
              <multi-select
                v-model="data['IdG_Producto']"
                :options="listaGaranttoProductos"
                filter
                emptyFilterMessage="No se encontraron resultados"
                optionValue="Id"
                optionLabel="Descripcion"
                placeholder="Seleccionar productos"
                :disabled="!userCanEditGarantto"
                :class="{
                  'p-invalid':
                    v$?.form?.MarcaCategoria?.$each?.$response?.$data?.[index]
                      ?.IdG_Producto?.$error && v$?.$dirty,
                }"
              />
            </template>
          </table-crud>
        </tab-panel>
      </tab-view>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, url, helpers } from "@vuelidate/validators";
import UploaderUrl from "@/components/UploaderUrl.vue";
import TableCrud from "@/components/TableCrud.vue";
import { dateParser } from "@/helpers/dateParser";
import FileUploader from "@/components/FileUploader.vue";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    UploaderUrl,
    TableCrud,
    FileUploader,
  },
  data() {
    return {
      imagen: null,
      form: { IdEstado: 1, MarcaCategoria: [] },
      columnsMarcaCategoria: [
        { field: "Categoria", header: "Categoría" },
        { field: "IdG_Producto", header: "Productos" },
      ],
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaGaranttoProductos: "cbx/getGaranttoProducto",
      userCanEditGarantto: "auth/userCanEditGarantto",
    }),
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    title() {
      if (this.esEdicion) return "Editar marca";
      return "Crear marca";
    },
    esLink() {
      if (!this.form.RutaInformacion) return false;
      if (this.v$?.form?.RutaInformacion?.$error) return false;
      return true;
    },
  },
  mounted() {
    this.getCbxGaranttoProducto();
  },
  methods: {
    ...mapActions({
      crear: "gmarcas/crear",
      actualizar: "gmarcas/actualizar",
      getCbxGaranttoProducto: "cbx/getCbxGaranttoProducto",
    }),
    resetModal() {
      this.form = { IdEstado: 1, MarcaCategoria: [] };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async cerrarModal() {
      const formHasChanged = this.v$.form.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;
      this.form.UltimaRevision = dateParser(this.form.UltimaRevision);
      if (this.esEdicion) this.editarHandler();
      else this.crearHandler();
    },
    async crearHandler() {
      let datos = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === 'MarcaCategoria') datos.append(key, JSON.stringify(this.form.MarcaCategoria))
        else if (value) datos.append(key, value)
      }
      if (this.imagen) datos.append('Imagen', this.imagen)

      const { data, status } = await this.crear(datos);
      if (status == 201) {
        this.resetModal();
        this.$toast.add(this.$alerts.creado);
        this.$emit("crear", data);
      }
    },
    async editarHandler() {
      let datos = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === 'MarcaCategoria') datos.append(key, JSON.stringify(this.form.MarcaCategoria))
        else if (value) datos.append(key, value)
      }
      if (this.imagen) datos.append('Imagen', this.imagen)
      datos.append('_method', 'put')

      const status = await this.actualizar(datos);
      if (status == 200) {
        this.resetModal();
        this.$toast.add(this.$alerts.actualizado);
      }
    },
    async onChangeFile({target}) {
      const [file] = target.files;
      this.imagen = file
      this.form.EliminarFoto = false
      this.form.Foto = 'Archivo seleccionado'
    },
    async onCleanFile() {
      this.imagen = null
      this.form.Foto = null
      this.form.EliminarFoto = true
    },
  },
  validations() {
    return {
      form: {
        IdEstado: { required },
        IdT: { required, maxLength: maxLength(255) },
        Descripcion: { maxLength: maxLength(255) },
        RutaInformacion: { url, maxLength: maxLength(255) },
        Comentarios: { maxLength: maxLength(1024) },
        MarcaCategoria: {
          $each: helpers.forEach({
            Categoria: {
              required,
            },
            IdG_Producto: {
              required,
            },
          }),
        },
        UltimaRevision: {},
      },
    };
  },
  watch: {
    datos(val) {
      if (val === null) return;
      this.form = { ...val };
    },
  },
};
</script>

<style lang="scss">
.gmarcas-modal {
  table .p-multiselect {
    & .p-multiselect-label {
      white-space: break-spaces;
    }
    &.p-inputwrapper {
      height: auto;
      min-width: 600px;
    }
  }
  &.modal-container table {
    .p-inputtext {
      min-width: 200px;
    }
  }
}
</style>
