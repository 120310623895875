<template>
  <span class="p-float-label">
    <slot />
    <label :class="{ 'input-required': required }">{{ label }}</label>
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
  },
};
</script>
