import axios from "./config";

export const listarTipoContrato = async (params) => {
  const { data, status } = await axios
    .get("/tipocontrato", { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarTipoContrato = async (id) => {
  const { data, status } = await axios
    .get(`/tipocontrato/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearTipoContrato = async (tipoContrato) => {
  const { data, status } = await axios
    .post("/tipocontrato", tipoContrato)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarTipoContrato = async (tipoContrato) => {
  const { data, status } = await axios
    .put(`/tipocontrato/${tipoContrato.Id}`, tipoContrato)
    .catch((e) => e.response);
  return { data, status };
};
