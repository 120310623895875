<template>
  <div>
    <table-crud
      :lista="ciudades"
      :columns="columns"
      title="Lista de ciudades"
      emptyMessage="No hay ciudades asociadas a este país"
      @crear="abrirModal = true"
      @editar="preEditar"
      @eliminar="eliminarCiudadHandler"
    />
    <paises-ciudades-modal
      v-model="abrirModal"
      v-model:datos="ciudad"
      ref="modal"
      @crear="crearCiudadHandler"
      @editar="editarCiudadHandler"
    />
  </div>
</template>

<script>
import TableCrud from "@/components/TableCrud.vue";
import PaisesCiudadesModal from "./PaisesCiudadesModal.vue";
import {
  crearCiudad,
  actualizarCiudad,
  eliminarCiudad,
} from "@/services/ciudades.js";

export default {
  props: {
    lista: {
      type: Array,
      default: () => [],
    },
    idPais: {
      type: [Number, String],
    },
  },
  components: {
    TableCrud,
    PaisesCiudadesModal,
  },
  data() {
    return {
      columns: [],
      ciudad: {},
      ciudades: [],
      abrirModal: false,
    };
  },
  created() {
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
      { field: "Estado", header: "Departamento" },
    ];
  },
  methods: {
    async crearCiudadHandler(ciudad) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await crearCiudad(this.idPais, ciudad);
      this.$store.commit("setIsLoading", false);
      if (status == 201) {
        this.ciudades.push(data);
        this.$refs.modal.resetModal();
        this.$toast.add(this.$alerts.creado);
      } else this.$alertStatus(status, data);
    },
    async editarCiudadHandler(ciudad) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await actualizarCiudad(this.idPais, ciudad);
      this.$store.commit("setIsLoading", false);
      if (status == 200) {
        const index = this.ciudades.findIndex((e) => e.Id == data.Id);
        this.ciudades[index] = data;
        this.$refs.modal.resetModal();
        this.$toast.add(this.$alerts.actualizado);
      } else this.$alertStatus(status, data);
    },
    async eliminarCiudadHandler(ciudad, index) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await eliminarCiudad(this.idPais, ciudad.Id);
      this.$store.commit("setIsLoading", false);
      if (status == 204) {
        this.ciudades.splice(index, 1);
        this.$toast.add(this.$alerts.eliminado);
      } else this.$alertStatus(status, data);
    },
    preEditar(item) {
      this.ciudad = item;
      this.abrirModal = true;
    },
  },
  watch: {
    lista: {
      handler(valor) {
        if (valor) {
          this.ciudades = [...valor];
        }
      },
      immediate: true,
    },
  },
};
</script>
