import Swal from "sweetalert2";

// Mensajes de alerta globales default para sweetalert

export const status500 = {
  mensaje: "Ocurrió un error interno, por favor intente mas tarde.",
  icono: "error",
};

export const status502 = {
  mensaje: "Se obtuvo una respuesta inválida del servidor.",
  icono: "error",
};

export const status207 = (mensajeError) => {
  let mensaje = "Operación exitosa, pero con errores detectados en el proceso.";
  if (mensajeError) mensaje = mensajeError;
  return {
    mensaje,
    icono: "success",
  };
};

export const status400 = (mensajeError) => {
  let mensaje = "El servidor no puede ejecutar esta acción.";
  if (mensajeError) mensaje = mensajeError;
  return {
    mensaje,
    icono: "warning",
  };
};

export const status405 = (camposErroneos) => {
  let mensaje = "El servidor no puede ejecutar esta acción actualmente.";
  if (camposErroneos) {
    mensaje = mensaje.concat("\nErrores:");
    for (const campo in camposErroneos) {
      mensaje = mensaje.concat(" ", `${camposErroneos[campo]}`);
    }
  }
  return {
    mensaje: mensaje,
    icono: "error",
  };
};

export const status404 = {
  mensaje: "No se encontró ninguna coincidencia, por favor intente nuevamente.",
  icono: "error",
};

export const status422 = (camposInvalidos) => {
  let mensaje =
    "Los datos ingresados no son validos, por favor intente nuevamente.";
  if (camposInvalidos) {
    mensaje = mensaje.concat("\nErrores:");
    for (const campo in camposInvalidos) {
      mensaje = mensaje.concat(" ", `${camposInvalidos[campo]}`);
    }
  }
  return {
    mensaje: mensaje,
    icono: "warning",
  };
};

export const descartarCambios = () => {
  if (!Swal.isVisible()) {
    return Swal.fire({
      title: "No se han guardado los cambios",
      text: "¿Desea descartar los cambios?",
      icon: "warning",
      iconColor: "#3fc3ee",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#037bff",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#dd3333",
    });
  }
};

export const eliminarRegistro = () => {
  if (!Swal.isVisible()) {
    return Swal.fire({
      title: "¿Quieres eliminar este registro?",
      text: "Al confirmar no podrás recuperar este registro.",
      icon: "warning",
      iconColor: "#3fc3ee",
      confirmButtonText: "Confirmar",
      confirmButtonColor: "#037bff",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#dd3333",
    });
  }
};

// Mensajes de alerta globales default para toast

const lifetime = 3500;

// Alertas generales
export const creado = {
  severity: "success",
  summary: "",
  detail: "El registro ha sido creado exitosamente.",
  life: lifetime,
};
export const actualizado = {
  severity: "success",
  summary: "",
  detail: "El registro ha sido actualizado exitosamente.",
  life: lifetime,
};
export const eliminado = {
  severity: "success",
  summary: "",
  detail: "El registro ha sido eliminado exitosamente.",
  life: lifetime,
};
export const duplicado = {
  severity: "success",
  summary: "",
  detail: "El registro ha sido duplicado exitosamente.",
  life: lifetime,
};
export const archivoGenerado = {
  severity: "success",
  summary: "",
  detail: "El archivo ha sido generado exitosamente.",
  life: 1000,
};
// Alertas logo
export const logoCargado = {
  severity: "success",
  summary: "",
  detail: "El logo ha sido cargado exitosamente.",
  life: lifetime,
};
export const logoEliminado = {
  severity: "success",
  summary: "",
  detail: "El logo ha sido eliminado exitosamente.",
  life: lifetime,
};
// Alertas clientes
export const clienteCreado = {
  severity: "success",
  summary: "",
  detail: "El nuevo cliente ha sido creado exitosamente.",
  life: lifetime,
};
export const clienteActualizado = {
  severity: "success",
  summary: "",
  detail: "El cliente ha sido actualizado exitosamente.",
  life: lifetime,
};
// Alertas contactos
export const contactoCreado = {
  severity: "success",
  summary: "",
  detail: "El nuevo contacto ha sido creado exitosamente.",
  life: lifetime,
};
export const contactoActualizado = {
  severity: "success",
  summary: "",
  detail: "El contacto ha sido actualizado exitosamente.",
  life: lifetime,
};
export const contactoEliminado = {
  severity: "success",
  summary: "",
  detail: "El contacto ha sido eliminado exitosamente.",
  life: lifetime,
};
// Alertas productos
export const productoCreado = {
  severity: "success",
  summary: "",
  detail: "El producto ha sido creado exitosamente.",
  life: lifetime,
};
export const productoActualizado = {
  severity: "success",
  summary: "",
  detail: "El producto ha sido actualizado exitosamente.",
  life: lifetime,
};
// Alertas tipos de contrato
export const tcontratoCreado = {
  severity: "success",
  summary: "",
  detail: "El tipo de contrato ha sido creado exitosamente.",
  life: lifetime,
};
export const tcontratoActualizado = {
  severity: "success",
  summary: "",
  detail: "El tipo de contrato ha sido actualizado exitosamente.",
  life: lifetime,
};
// Alertas sedes
export const sedeCreado = {
  severity: "success",
  summary: "",
  detail: "La sede ha sido creada exitosamente.",
  life: lifetime,
};
export const sedeActualizado = {
  severity: "success",
  summary: "",
  detail: "La sede ha sido actualizada exitosamente",
  life: lifetime,
};
// Alertas contratos
export const contratoCreado = {
  severity: "success",
  summary: "",
  detail: "El contrato ha sido creado exitosamente.",
  life: lifetime,
};
export const contratoActualizado = {
  severity: "success",
  summary: "",
  detail: "El contrato ha sido actualizado exitosamente",
  life: lifetime,
};
export const contratoDuplicado = {
  severity: "success",
  summary: "",
  detail: "El contrato ha sido duplicado exitosamente",
  life: lifetime,
};
// Alertas atributos
export const atributoCreado = {
  severity: "success",
  summary: "",
  detail: "El atributo ha sido creado exitosamente.",
  life: lifetime,
};
export const atributoActualizado = {
  severity: "success",
  summary: "",
  detail: "El atributo ha sido actualizado exitosamente",
  life: 1000,
};
export const atributoAsociado = {
  severity: "success",
  summary: "",
  detail: "El atributo ha sido asociado exitosamente.",
  life: 1000,
};
export const atributoEliminado = {
  severity: "success",
  summary: "",
  detail: "El atributo ha sido eliminado exitosamente.",
  life: 1000,
};
