import { crearSede, actualizarSede } from "@/services/sede";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async crear({ commit }, { datos, clienteId }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearSede(datos, clienteId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarSede(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
