import axios from "./config";

export const crearContacto = async (contacto) => {
  const { data, status } = await axios
    .post("contactos", contacto)
    .catch((e) => e.response);
  return { data, status };
};

export const editarContacto = async (contacto) => {
  const { data, status } = await axios
    .put(`contactos/${contacto.Id}`, contacto)
    .catch((e) => e.response);
  return { data, status };
};

export const buscarContacto = async (contactoId) => {
  const { data, status } = await axios
    .get(`contactos/${contactoId}`)
    .catch((e) => e.response);
  return { data, status };
};

export const listarCbxContactos = async (params) => {
  const { data, status } = await axios
    .get("/cbx/contactos", { params })
    .catch((e) => e.response);
  return { data, status };
};

export const listarContactos = async (params) => {
  const { data, status } = await axios
    .get("/contactos", { params })
    .catch((e) => e.response);
  return { data, status };
};
