import axios from "./config";

export const buscarContrato = async (contratoId) => {
  const { data, status } = await axios
    .get(`/contrato/${contratoId}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearContrato = async (contrato, clienteId) => {
  const { data, status } = await axios
    .post(`/cliente/${clienteId}/contrato`, contrato)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarContrato = async (contrato, clienteId) => {
  const { data, status } = await axios
    .put(`/cliente/${clienteId}/contrato/${contrato.Id}`, contrato)
    .catch((e) => e.response);
  return { data, status };
};

export const duplicarContrato = async (contratoId) => {
  const { data, status } = await axios
    .post(`/contrato/${contratoId}/duplicar`)
    .catch((e) => e.response);
  return { data, status };
};
