<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de países</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="abrirModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-component
    :options="lista"
    :columns="columns"
    :filters="filters"
    empty="No se encontraron países..."
    buttonDelete
    :disableEditButton="!userCanEdit"
    :disableDeleteButton="!userCanEdit"
    @abrir-editar="preEditar"
    @eliminar="(data) => eliminar(data.Id)"
  />
  <!-- Modales -->
  <paises-modal v-model="abrirModal" v-model:datos="pais" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import TableComponent from "@/components/TableComponent.vue";
import PaisesModal from "@/components/maestros/paises/PaisesModal.vue";
import { buscarPais } from "@/services/paises.js";

export default {
  components: { TableComponent, PaisesModal },
  data() {
    return {
      pais: {},
      columns: [],
      filters: [],
      abrirModal: false,
    };
  },
  computed: {
    ...mapGetters({
      lista: "paises/getLista",
      total: "paises/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
    ];
    // Filtros de la tabla
    this.filters = {
      IdT: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Descripcion: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "paises/listar",
      eliminar: "paises/eliminar",
    }),
    async preEditar(item) {
      if (item) {
        this.$store.commit("setIsLoading", true);
        const { data, status } = await buscarPais(item.Id);
        this.$store.commit("setIsLoading", false);
        if (status == 200) {
          this.pais = data;
          this.abrirModal = true;
        } else this.$alertStatus(status, data);
      }
    },
  },
};
</script>
