export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  let responseValue = null;
  for (let i in cookies) {
    const [key, ...value] = cookies[i].split("=");
    if (key.trim() == name) {
      responseValue = value.join("=");
      break;
    }
  }
  return responseValue;
};

export const setCookie = (name, value, expires, path = "/") => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + expires);
  const cookie = `${name}=${value};expires=${date.toUTCString()};path=${path};`;
  document.cookie = cookie;
};

export const deleteCookie = (name) => {
  const date = new Date();
  date.setMinutes(-1);
  const cookie = `${name}='';expires=${date.toUTCString()};path=/;`;
  document.cookie = cookie;
};

export const whatsappValidator = (input) => {
  if (input === undefined || input === null) {
    return true;
  }
  const regex = /^(?:\+?\d+|\d+)$/;
  return regex.test(input);
};

export const formatToLocalTime = (input) => {
  if (typeof input !== "string" && !(input instanceof Date)) {
    return null;
  }
  return new Date(input + "z").toLocaleString("es-CO");
};
