const state = {
  isLoading: false, // Estado del elemento de carga para toda la aplicacion
  esMovil: false,
  porPagina: 15, // Numero de registros por pagina default (datatable)
  alturaTabla: null, // Altura de las tablas por pantalla
  sidebarVisible: false,
};

const getters = {
  // 3 son las filas por pagina que se deben restar debido a la altura de los elementos extra
  // que existen en la vista tabview, que no son tomados en cuenta en el algoritmo original.
  porPaginaTabview(state) {
    const filas = state.porPagina - 3;
    return filas <= 0 ? 1 : filas;
  },
  rutaBase() {
    return process.env?.VUE_APP_API_URL ?? location.origin;
  },
};

const actions = {};

const mutations = {
  setIsLoading: (state, toggle) => (state.isLoading = toggle),
  setEsMovil: (state, value) => (state.esMovil = value),
  setPorPagina: (state, value) => (state.porPagina = value),
  setAlturaTabla: (state, value) => (state.alturaTabla = value),
  setSidebarVisible: (state) => (state.sidebarVisible = !state.sidebarVisible),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
