import Swal from "sweetalert2";

export const swal = ({ titulo, mensaje, icono }) => {
  if (!Swal.isVisible()) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: icono,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#037bff",
    });
  }
};
