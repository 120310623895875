import {
  crearMarca,
  actualizarMarca,
  eliminarMarca,
  listarMarca,
} from "../services/gmarcas";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setCreated: ({ data }, marca) => data.data.push(marca),
  setUpdated: ({ data }, marca) => {
    const index = data.data.findIndex((e) => e.Id == marca.Id);
    data.data[index] = marca;
  },
  setDeleted: ({ data }, idMarca) => {
    const index = data.data.findIndex((e) => e.Id == idMarca);
    data.data.splice(index, 1);
  },
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarMarca(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async crear({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearMarca(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 201
      ? commit("setCreated", data)
      : this.$alertStatus(status, data);

    return { data, status };
  },
  async actualizar({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarMarca(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 200
      ? commit("setUpdated", data)
      : this.$alertStatus(status, data);

    return status;
  },
  async eliminar({ commit }, idMarca) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await eliminarMarca(idMarca);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 204
      ? commit("setDeleted", idMarca)
      : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
