import { swal } from "./swalConfig";
import {
  status207,
  status400,
  status404,
  status405,
  status422,
  status500,
  status502,
} from "./alertMessages";

export const alertStatus = (status, data) => {
  switch (status) {
    case 207:
      swal(status207(data?.message));
      break;
    case 400:
      swal(status400(data?.message));
      break;
    case 404:
      swal(status404);
      break;
    case 405:
      swal(status405(data?.errors));
      break;
    case 422:
      swal(status422(data?.errors));
      break;
    case 500:
      swal(status500);
      break;
    case 502:
      swal(status502);
      break;
  }
};
