<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de contactos</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="abrirModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :empty="emptyMsg"
    :totalRecords="total"
    @editar="preEditar"
    @generatePage="listar"
  />
  <!-- Modal -->
  <contacto-modal
    v-model="abrirModal"
    v-model:datos="contacto"
    @crear="add"
    @editar="update"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import ContactoModal from "@/components/contacto/ContactoModal.vue";

export default {
  components: { TableDataLazy, ContactoModal },
  data() {
    return {
      contacto: {},
      columns: [],
      emptyMsg: "No se encontraron contactos...",
      abrirModal: false,
    };
  },
  computed: {
    ...mapGetters({
      lista: "contactos/getLista",
      total: "contactos/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "Nombre1", header: "Nombre" },
      { field: "Apellido1", header: "Apellido" },
      { field: "Email", header: "Email" },
      { field: "Celular", header: "Celular" },
      { field: "Identificacion", header: "Identificación" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "contactos/listar",
    }),
    ...mapMutations({
      add: "contactos/add",
      update: "contactos/update",
    }),
    preEditar(data) {
      if (data) {
        this.contacto = data;
        this.abrirModal = true;
      }
    },
  },
};
</script>
