import axios from "./config";

export const crearClienteContacto = async (contacto, clienteId) => {
  const { data, status } = await axios
    .post(`/cliente/${clienteId}/contactos`, contacto)
    .catch((e) => e.response);
  return { data, status };
};

export const editarClienteContacto = async (
  contacto,
  clienteId,
  clienteContactoId
) => {
  const { data, status } = await axios
    .put(`/cliente/${clienteId}/contactos/${clienteContactoId}`, contacto)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarClienteContacto = async (clienteId, clienteContactoId) => {
  const { data, status } = await axios
    .delete(`/cliente/${clienteId}/contactos/${clienteContactoId}`)
    .catch((e) => e.response);
  return { data, status };
};
