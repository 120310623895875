import store from "../store";
import { getCookie } from "@/helpers";
import { checkToken } from "@/services/auth";

export const authCheck = async (to, from, next) => {
  const cookieExist = getCookie("authToken");

  if (to.name == "login") {
    if (!cookieExist) next();
    else next("/");
    return;
  }

  if (!cookieExist) {
    next("login");
    return;
  }

  // Check si el usuario esta autenticado
  store.commit("setIsLoading", true);
  const {data:{user, asset_url}} = await checkToken();
  store.commit("setIsLoading", false);

  if (user) {
    store.commit("auth/login", user);
    store.commit("auth/setAssetUrl", asset_url);
    next();
  } else {
    store.commit("auth/logout", "authToken");
    next("login");
  }
};
