<template>
  <div>
    <data-table
      :value="lista"
      :editMode="editMode"
      v-bind="$attrs"
      v-model:editingRows="editingRows"
      @row-edit-save="(e) => $emit('editar', e)"
      @row-edit-cancel="(e) => $emit('editarCancelar', e)"
      showGridlines
    >
      <column-element
        v-if="showCrudColumn"
        :bodyStyle="`width:${crudColumnWidth}; white-space: nowrap`"
        :headerStyle="`width:${crudColumnWidth}; white-space: nowrap`"
        headerClass="column-button"
      >
        <template #header>
          <button-element
            v-if="showAddButton"
            icon="pi pi-plus"
            class="p-button-primary border-circle"
            style="width: 30px; height: 30px"
            @click="$emit('crear')"
          />
        </template>
        <template #body="{ data, index }">
          <button-element
            v-if="showDeleteButton"
            icon="pi pi-trash"
            class="p-button-secondary p-button-text border-circle"
            style="width: 30px; height: 30px"
            :disabled="data?.disableDelete"
            @click="onDelete(data, index)"
          />
          <button-element
            v-if="showEditButton && !inlineEditing"
            icon="pi pi-pencil"
            class="p-button-secondary p-button-text border-circle"
            style="width: 30px; height: 30px"
            @click="$emit('editar', data, index)"
          />
        </template>
      </column-element>
      <column-element
        v-if="inlineEditing && !isListEmtpy"
        :rowEditor="true"
        style="min-width: 85px; width: 85px"
        bodyStyle="text-align:center"
      />
      <column-element
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        bodyStyle="white-space:nowrap;"
      >
        <template #editor="{ data }">
          <slot
            :value="data[col.field]"
            :data="data"
            :field="col.field"
            :name="`${col.field}Edit`"
          >
            <input-text v-model="data[col.field]" />
          </slot>
        </template>
        <template #body="{ data, index }">
          <slot
            :value="data[col.field]"
            :data="data"
            :name="col.field"
            :index="index"
          >
            {{ data[col.field] }}
          </slot>
        </template>
      </column-element>
      <template v-if="showHeader" #header>
        <div class="flex justify-content-between align-items-center h-full">
          <span>{{ title }}</span>
        </div>
      </template>
      <template #empty>
        {{ emptyMessage }}
      </template>
    </data-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Lista de datos",
    },
    lista: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: String,
      default: "row",
    },
    inlineEditing: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: "No hay registros",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editingRows: [],
    };
  },
  computed: {
    isListEmtpy() {
      return this.lista?.length == 0;
    },
    crudColumnWidth() {
      return this.showDeleteButton && this.showEditButton && !this.inlineEditing
        ? "71px"
        : "42px";
    },
    showCrudColumn() {
      return this.showDeleteButton || this.showEditButton || this.showAddButton;
    },
  },
  methods: {
    async onDelete(data, index) {
      const res = await this.$alerts.eliminarRegistro();

      if (res.isConfirmed) {
        this.$emit("eliminar", data, index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable) {
  .p-datatable-wrapper {
    margin-bottom: 10px !important;
    box-shadow: none !important;
  }
  .p-datatable-header {
    padding: 8px;
    background: #f0f2f5;
    border-bottom: 1px solid lightgray;
  }
  .p-datatable-thead > tr > th {
    background-color: #f9f9f9 !important;
    border: 1px solid lightgray !important;
  }
  .column-button .p-column-header-content {
    justify-content: center;
  }
}
</style>
