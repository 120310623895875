<template>
  <div
    class="h-screen w-screen fixed bg-white flex align-items-center overflow-hidden opacity-70 loader-zindex"
  >
    <progress-spinner strokeWidth="4" />
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: {
    ProgressSpinner,
  },
};
</script>

<style>
@keyframes p-progress-spinner-color {
  0%,
  100% {
    stroke: #0057e7;
  }
}
</style>
