<template>
  <Card-element>
    <template #title> Módulos </template>
    <template #content>
      <div class="flex justify-content-center pb-4 mt-3">
        <div class="box-modulos mx-3">
          <div
            class="box-icon-moduls flex justify-content-center"
            :style="{ backgroundColor: moduloColor(modulos?.ventas) }"
          >
            <i
              class="pi pi-dollar py-2"
              :style="{ color: iconoColor(modulos?.ventas) }"
            ></i>
          </div>
          <div class="icon-check flex justify-content-end">
            <i
              :style="{ color: iconCheck(modulos?.ventas) }"
              class="pi pi-check-circle mr-2"
            ></i>
          </div>
          <p
            class="text-center"
            :style="{ color: iconoColor(modulos?.ventas) }"
          >
            Ventas
          </p>
        </div>
        <div class="box-modulos">
          <div
            class="box-icon-moduls flex justify-content-center"
            :style="{ backgroundColor: moduloColor(modulos?.compras) }"
          >
            <i
              class="pi pi-shopping-cart py-2"
              :style="{ color: iconoColor(modulos?.compras) }"
            ></i>
          </div>
          <div class="icon-check flex justify-content-end">
            <i
              :style="{ color: iconCheck(modulos?.compras) }"
              class="pi pi-check-circle mr-2"
            ></i>
          </div>
          <p
            class="text-center mx-3"
            :style="{ color: iconoColor(modulos?.compras) }"
          >
            Compras e <br />
            inventarios
          </p>
        </div>
        <div class="box-modulos mx-3">
          <div
            class="box-icon-moduls flex justify-content-center"
            :style="{ backgroundColor: moduloColor(modulos?.contabilidad) }"
          >
            <i
              class="pi pi-chart-bar py-2"
              :style="{ color: iconoColor(modulos?.contabilidad) }"
            ></i>
          </div>
          <div class="icon-check flex justify-content-end">
            <i
              :style="{ color: iconCheck(modulos?.contabilidad) }"
              class="pi pi-check-circle mr-2"
            ></i>
          </div>
          <p
            class="text-center"
            :style="{ color: iconoColor(modulos?.contabilidad) }"
          >
            Contabilidad
          </p>
        </div>
      </div>
    </template>
  </Card-element>
</template>
<script>
export default {
  props: {
    modulos: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    moduloColor(value) {
      return value ? "#F6E5FC" : "#E4E4E4";
    },
    iconoColor(value) {
      return value ? "#A203DD" : "#A6A6A6";
    },
    iconCheck(value) {
      return value ? "#00ba00" : "#FFFFFF";
    },
  },
};
</script>
