<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.contacto.$error" />
    <div class="formgrid grid my-5">
      <div class="col-6 mt-3">
        <p-float-label label="Tipo de documento" required>
          <dropdown-element
            v-model="v$.contacto.IdTipoDocumento.$model"
            :loading="isLoading"
            :options="listaTipoDocumento"
            optionLabel="Descripcion"
            optionValue="Id"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.IdTipoDocumento?.$error,
            }"
            @change="
              (e) => {
                if (e.value == 16) contacto.Identificacion = null;
              }
            "
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-3">
        <p-float-label label="Identificación">
          <input-text
            v-model="v$.contacto.Identificacion.$model"
            type="text"
            :disabled="contacto.IdTipoDocumento == 16 || !userCanEdit"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Primer nombre" required>
          <input-text
            type="text"
            v-model="v$.contacto.Nombre1.$model"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.Nombre1?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Segundo nombre">
          <input-text
            type="text"
            v-model="v$.contacto.Nombre2.$model"
            :disabled="!userCanEdit"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Primer apellido" required>
          <input-text
            type="text"
            v-model="v$.contacto.Apellido1.$model"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.Apellido1?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Segundo apellido">
          <input-text
            type="text"
            v-model="v$.contacto.Apellido2.$model"
            :disabled="!userCanEdit"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Celular" required>
          <input-text
            type="text"
            v-model="v$.contacto.Celular.$model"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.Celular?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Teléfono">
          <input-text
            type="text"
            v-model="v$.contacto.Telefono.$model"
            :disabled="!userCanEdit"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Email" required>
          <input-text
            type="text"
            v-model="v$.contacto.Email.$model"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.Email?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Estado" required>
          <dropdown-element
            v-model="v$.contacto.IdEstado.$model"
            :loading="isLoading"
            :options="listaEstados"
            optionLabel="Descripcion"
            optionValue="Id"
            :disabled="!userCanEdit"
            :class="{
              'p-invalid': v$?.contacto?.IdEstado?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Comentario">
          <text-area
            v-model="v$.contacto.Comentario.$model"
            rows="4"
            :disabled="!userCanEdit"
          />
        </p-float-label>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { crearContacto, editarContacto } from "@/services/contactos";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    datos: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contacto: {
        IdEstado: 1,
        IdTipoDocumento: 16,
      },
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaTipoDocumento: "cbx/getTipoDocumento",
      userCanEdit: "auth/userCanEdit",
    }),
    title() {
      if (this.esEdicion) return "Editar contacto";
      return "Crear contacto";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
  },
  methods: {
    async cerrarModal() {
      const formHasChanged = this.v$.contacto.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.contacto.$validate();
      if (!isFormCorrect) return;
      this.$store.commit("setIsLoading", true);
      if (this.esEdicion) await this.editarContacto();
      else await this.crearContacto();
      this.$store.commit("setIsLoading", false);
    },
    resetModal() {
      this.contacto = {
        IdEstado: 1,
        IdTipoDocumento: 16,
      };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async crearContacto() {
      const { data, status } = await crearContacto(this.contacto);
      if (status == 201) {
        this.$emit("crear", data);
        this.resetModal();
        this.$toast.add(this.$alerts.contactoCreado);
      } else this.$alertStatus(status, data);
    },
    async editarContacto() {
      const { data, status } = await editarContacto(this.contacto);
      if (status == 200) {
        this.$emit("editar", data);
        this.resetModal();
        this.$toast.add(this.$alerts.contactoActualizado);
      } else this.$alertStatus(status, data);
    },
  },
  validations() {
    return {
      contacto: {
        IdTipoDocumento: { required },
        IdEstado: { required },
        Nombre1: { required },
        Apellido1: { required },
        Email: { required, email },
        Celular: { required },
        Identificacion: {},
        Nombre2: {},
        Apellido2: {},
        Comentario: {},
        Telefono: {},
      },
    };
  },
  watch: {
    datos(value) {
      if (value) this.contacto = { ...this.datos };
    },
  },
};
</script>
