import {
  buscarProducto,
  crearProducto,
  actualizarProducto,
  listarProducto,
  productoAtributoListar,
  productoAtributoCrear,
  productoAtributoActualizar,
  productoAtributoEliminar,
} from "@/services/productos";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setCreado: ({ data }, producto) => data.data.push(producto),
  setActualizado: ({ data }, producto) => {
    data.data = data.data.map((element) =>
      element.Id == producto.Id ? producto : element
    );
  },
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarProducto(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async buscar({ commit }, id) {
    if (!id) id = 0;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await buscarProducto(id);
    commit("setIsLoading", false, { root: true });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async crear({ commit }, producto) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearProducto(producto);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, producto) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarProducto(producto);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async listarProductoAtributo({ commit }, productoId) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await productoAtributoListar(productoId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async crearProductoAtributo({ commit }, { productoId, atributo }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await productoAtributoCrear(productoId, atributo);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizarProductoAtributo(
    { commit },
    { productoId, atributoId, atributoNuevo }
  ) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await productoAtributoActualizar(
      productoId,
      atributoId,
      atributoNuevo
    );
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async eliminarProductoAtributo({ commit }, { productoId, atributoId }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await productoAtributoEliminar(
      productoId,
      atributoId
    );
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 204 ? true : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
