import { actualizarLogo, eliminarLogo } from "@/services/logo";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async actualizar({ commit }, { logo, clienteId }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarLogo(logo, clienteId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async eliminar({ commit }, clienteId) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await eliminarLogo(clienteId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 204 ? true : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
