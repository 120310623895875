import { createStore } from "vuex";
// Default
import _default from "./modules/default";
// Modulos
import auth from "./modules/auth";
import cbx from "./modules/cbx";
import logo from "./modules/logo";
import clientes from "./modules/clientes";
import contactos from "./modules/contactos";
import sede from "./modules/sede";
import productos from "./modules/productos";
import contratos from "./modules/contratos";
import paises from "./modules/paises";
import atributos from "./modules/atributos";
import tipoContrato from "./modules/tipoContrato";
import { GaranttoStores } from "@/modules/garantto/stores";

export default createStore({
  ..._default,
  modules: {
    auth,
    cbx,
    logo,
    clientes,
    contactos,
    sede,
    productos,
    contratos,
    paises,
    atributos,
    tipoContrato,
    ...GaranttoStores,
  },
});
