<template>
  <div class="p-inputgroup">
    <span class="p-float-label">
      <input-text
        @click="clickAnchorTag"
        v-model="fileValue"
        :class="{ 
          'input-anchor': fileValue && !esTipoArchivo,
          'p-invalid': invalid, 
        }"
        :readonly="fileValue"
        :disabled="!fileValue || esTipoArchivo"
      />
      <a
        :href="$asset(value)"
        ref="anchorTag"
        target="_blank"
        class="hidden"
      />
      <label>{{ label }}</label>
    </span>
    <button-element
      v-if="fileValue"
      icon="pi pi-trash"
      class="p-button-danger"
      :disabled="disabled"
      @click="onCleanFile"
    />
    <button-element
      icon="pi pi-folder-open"
      class="p-button-success"
      :disabled="disabled"
      @click="clickInputFile"
    />
    <input
      @change="onChangeFile"
      ref="inputFile"
      type="file"
      :accept="accept"
      class="hidden"
    />
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    value: [String, File],
    disabled: Boolean,
    auto: {
      type: Boolean,
      default: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: "image/*"
    },
    emitLinkEvent: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    fileValue() {
      return this.auto ? (this.value?.split("/")?.pop() ?? null) : (this.esTipoArchivo ?? this.value);
    },
    esTipoArchivo() {
      return this.value?.name;
    },
  },
  methods: {
    clickAnchorTag() {
      if (this.emitLinkEvent) {
        this.$emit("clickLink");
        return;
      }
      this.$refs.anchorTag.click();
    },
    clickInputFile() {
      this.$refs.inputFile.click();
    },
    onCleanFile() {
      this.$refs.inputFile.value = null;
      this.$emit("cleanFile");
    },
    onChangeFile(e) {
      this.$emit("changeFile", e);
    },
  },
};
</script>
