<template>
  <modal-wrapper
    title="Añadir atributo"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.atributo.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <dropdown-element
          v-model="v$.atributo.IdAtributo.$model"
          :loading="isLoading"
          :options="listaAtributos"
          placeholder="Selecciona un atributo"
          :optionLabel="(e) => `${e.IdT} - ${e.Descripcion}`"
          optionValue="Id"
          :class="{
            'p-invalid': v$?.atributo?.IdAtributo?.$error,
          }"
        />
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
    productoId: Number,
  },
  data() {
    return {
      atributo: {
        IdAtributo: null,
      },
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaAtributos: "cbx/getAtributo",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  methods: {
    ...mapActions({
      crearProductoAtributo: "productos/crearProductoAtributo",
    }),
    async cerrarModal() {
      const formHasChanged = this.v$.atributo.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.atributo.$validate();
      if (!isFormCorrect) return;
      const data = await this.crearAtributo();
      if (data) {
        this.$emit("mostrarAtributo", data);
        this.resetModal();
        this.$toast.add(this.$alerts.atributoAsociado);
      }
    },
    resetModal() {
      this.atributo = {
        IdAtributo: null,
      };
      this.v$.$reset();
      this.$emit("update:modelValue", false);
    },
    async crearAtributo() {
      const payload = { productoId: this.productoId, atributo: this.atributo };
      const res = await this.crearProductoAtributo(payload);
      if (res) return res;
    },
  },
  validations() {
    return {
      atributo: {
        IdAtributo: { required },
      },
    };
  },
};
</script>
