<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.form.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <p-float-label label="Código" required>
          <input-text
            type="text"
            v-model="v$.form.IdT.$model"
            :class="{
              'p-invalid': v$?.form?.IdT?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Descripción" required>
          <input-text
            type="text"
            v-model="v$.form.Descripcion.$model"
            :class="{
              'p-invalid': v$?.form?.Descripcion?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div v-if="esEdicion" class="col-12 mt-3">
        <paises-ciudades-tab :lista="form.ciudades" :idPais="form.Id" />
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaisesCiudadesTab from "@/components/maestros/paises/PaisesCiudadesTab.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: { PaisesCiudadesTab },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters({ userCanEdit: "auth/userCanEdit" }),
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    title() {
      if (this.esEdicion) return "Editar país";
      return "Crear país";
    },
  },
  methods: {
    ...mapActions({
      crear: "paises/crear",
      actualizar: "paises/actualizar",
    }),
    resetModal() {
      this.form = {};
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async cerrarModal() {
      const formHasChanged = this.v$.form.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;
      if (this.esEdicion) this.editarPaisHandler();
      else this.crearPaisHandler();
    },
    async crearPaisHandler() {
      const status = await this.crear(this.form);
      if (status == 201) {
        this.resetModal();
        this.$toast.add(this.$alerts.creado);
      }
    },
    async editarPaisHandler() {
      const status = await this.actualizar(this.form);
      if (status == 200) {
        this.resetModal();
        this.$toast.add(this.$alerts.actualizado);
      }
    },
  },
  validations() {
    return {
      form: {
        IdT: { required },
        Descripcion: { required },
      },
    };
  },
  watch: {
    datos(val) {
      if (val === null) return;
      this.form = { ...val };
    },
  },
};
</script>
