<template>
  <toolbar-element class="submenu-tabview">
    <template #start>
      <h3 class="m-0">Lista de contactos</h3>
    </template>
    <template #end>
      <button-element
        label="Nuevo contacto"
        icon="pi pi-plus"
        class="p-button-success"
        :disabled="!userCanEdit"
        @click="abrirModal = true"
      />
    </template>
  </toolbar-element>
  <table-component
    :options="contactos"
    :columns="columns"
    :filters="filters"
    :empty="emptyMsg"
    buttonDelete
    :disableEditButton="!userCanEdit"
    :disableDeleteButton="!userCanEdit"
    @abrir-editar="preEditar"
    @eliminar="eliminarClienteContacto"
  >
    <template #Principal="{ data }">
      <i
        :class="data.Principal ? 'pi pi-check' : 'pi pi-times'"
        :style="data.Principal ? 'color: green' : 'color: red'"
      ></i>
    </template>
  </table-component>
  <cliente-contacto-modal v-model="abrirModal" v-model:contacto="contacto" />
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import TableComponent from "@/components/TableComponent.vue";
import ClienteContactoModal from "@/components/cliente/ClienteContactoModal.vue";
import { eliminarClienteContacto } from "@/services/clienteContacto";

export default {
  components: { TableComponent, ClienteContactoModal },
  data() {
    return {
      contacto: null,
      columns: null,
      filters: null,
      abrirModal: false,
      emptyMsg: "No se han encontrado contactos...",
    };
  },
  computed: {
    ...mapGetters({
      contactos: "clientes/getContactos",
      userCanEdit: "auth/userCanEdit",
    }),
    clienteId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.columns = [
      { field: "Nombre1", header: "Nombre" },
      { field: "Apellido1", header: "Apellido" },
      { field: "Email", header: "Email" },
      { field: "Celular", header: "Celular" },
      { field: "Identificacion", header: "Identificación" },
      { field: "Principal", header: "Principal" },
    ];
    this.filters = {
      Nombre1: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Apellido1: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Celular: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Identificacion: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Principal: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
  },
  methods: {
    preEditar(data) {
      if (data) {
        this.contacto = data;
        this.abrirModal = true;
      }
    },
    async eliminarClienteContacto({ IdClienteContacto }) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await eliminarClienteContacto(
        this.clienteId,
        IdClienteContacto
      );
      this.$store.commit("setIsLoading", false);
      if (status == 204) {
        this.$toast.add(this.$alerts.eliminado);
        this.$store.commit("clientes/setContactosDelete", IdClienteContacto);
      } else this.$alertStatus(status, data);
    },
  },
};
</script>
