const num = (val) => {
  return val?.toString().replace(/[\D]/, "");
};

const separarMiles = (valor, separador = ".") => {
  let resultado = num(valor)
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(?=\d*\.?)(\d{3})/g, "$1" + separador);
  resultado = resultado.split("").reverse().join("").replace(/^[.]/, "");
  return resultado;
};

const currencyEncode = (valor, valorVacio = "$ -") => {
  if (!valor) return valorVacio;
  let result = parseFloat(valor)?.toFixed(2)?.toString()?.split(".") || [];
  result[0] = separarMiles(result[0] || "");
  return `$${Math.sign(valor) < 0 ? "-" : ""}${result.join(",")}`;
};

const percentageEncode = (valor, valorVacio = "") => {
  if (!valor) return valorVacio;
  let result =
    parseFloat(valor * 100)
      ?.toFixed(2)
      ?.toString()
      ?.split(".") || [];
  result[0] = separarMiles(result[0] || "");
  return `${Math.sign(valor) < 0 ? "-" : ""}${result.join(",")}%`;
};

export default {
  install(app) {
    app.config.globalProperties.$common = {
      percentageEncode,
      currencyEncode,
      separarMiles,
      num,
    };
  },
};
