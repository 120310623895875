export const dv = (documento) => {
  documento = parseInt(documento).toString();
  const es_solo_numero = new RegExp("^[0-9]+$").test(documento);
  let x = 0,
    y = 0,
    z = documento.length;
  const lista_dv = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
  if (!es_solo_numero) return "";
  documento.split("").forEach((value, i) => {
    y = parseInt(value);
    x += y * lista_dv[z - (i + 1)];
  });
  y = x % 11;
  return y > 1 ? 11 - y : y;
};
