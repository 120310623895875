<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de productos</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEditGarantto"
        @click="abrirModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :totalRecords="total"
    :empty="emptyMsg"
    :buttonDelete="userCanEditGarantto"
    :sortable="true"
    @editar="preEditar"
    @generatePage="listar"
    @eliminar="(data) => eliminar(data.Id)"
  >
    <template #IdEstado="{ value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: value == 1 ? '#c8e6c9' : '#e1e1e1' }"
      >
        {{ value == 1 ? "Habilitado" : "Inhabilitado" }}
      </span>
    </template>
  </table-data-lazy>
  <!-- Modal -->
  <g-productos-modal v-model="abrirModal" v-model:datos="producto" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import GProductosModal from "../components/GProductosModal.vue";
import { buscarProducto } from "../services/gproductos";

export default {
  components: { TableDataLazy, GProductosModal },
  data() {
    return {
      producto: {},
      columns: [],
      abrirModal: false,
      emptyMsg: "No se encontraron productos...",
    };
  },
  computed: {
    ...mapGetters({
      lista: "gproductos/getLista",
      total: "gproductos/getTotal",
      userCanEditGarantto: "auth/userCanEditGarantto",
      dbPais:"gpais/getPais",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Alias", header: "Alias" },
      { field: "IdEstado", header: "Estado" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "gproductos/listar",
      eliminar: "gproductos/eliminar",
    }),
    async preEditar(item) {
      if (item) {
        this.$store.commit("setIsLoading", true);
        const { data, status } = await buscarProducto(item.Id);
        this.$store.commit("setIsLoading", false);
        if (status == 200) {
          this.producto = data;
          this.abrirModal = true;
        } else this.$alertStatus(status, data);
      }
    },
  },
  watch: {
    dbPais() {
      this.listar()
    }
  }
};
</script>
