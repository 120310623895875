import axios from "@/services/config";
import store from "@/store/index"

const codigoPais = () => store.state.gpais.dbPais.IdT

export const listarCompania = async (params) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/companias`, { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarCompania = async (id) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/companias/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearCompania = async (compania) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/companias`, compania)
    .catch((e) => e.response);
  return { data, status };
};

export const crearLicenciaCompania = async (id) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/companias/${id}/crear-licencia`)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarCompania = async (compania) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .put(`/garantto/${gpais}/companias/${compania.Id}`, compania)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarCompania = async (idCompania) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .delete(`/garantto/${gpais}/companias/${idCompania}`)
    .catch((e) => e.response);
  return { data, status };
};

export const duplicarCompania = async (idCompania) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/companias/${idCompania}/duplicar`)
    .catch((e) => e.response);
  return { data, status };
};
