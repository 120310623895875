<template>
  <data-table
    :value="options"
    paginator
    stripedRows
    ref="dt"
    :lazy="true"
    :rows="porPagina"
    :pageLinkSize="3"
    :rowsPerPageOptions="[porPagina, 30, 40, 50]"
    :totalRecords="totalRecords"
    removableSort
    filterDisplay="row"
    responsiveLayout="stack"
    breakpoint="767px"
    currentPageReportTemplate="{first} - {last} de {totalRecords} resultados"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
    @page="onPage($event)"
    @sort="onSort($event)"
  >
    <template #empty> {{ empty }} </template>
    <column-element style="width: 46px">
      <template #body="{ data }">
        <div class="flex">
          <button-element
            v-if="buttonEdit"
            icon="pi pi-eye"
            class="p-button-success p-button-rounded py-1"
            :disabled="disableEditButton"
            @click="editar(data)"
          />
          <button-element
            v-if="buttonDelete"
            icon="pi pi-trash"
            class="p-button-danger p-button-rounded py-1 ml-1"
            :disabled="disableDeleteButton"
            @click="eliminar(data)"
          />
        </div>
      </template>
    </column-element>
    <column-element
      v-for="col of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field"
      :sortable="sortable"
      bodyStyle="white-space:nowrap;"
    >
      <template #filter>
        <input-text
          type="text"
          v-model="filters[col.field]"
          @keydown.enter="onFilter($event)"
        />
      </template>
      <template #body="{ data }">
        <slot :value="data[col.field]" :data="data" :name="col.field">{{
          data[col.field]
        }}</slot>
      </template>
    </column-element>
  </data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    empty: {
      type: String,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    buttonEdit: {
      type: Boolean,
      default: true,
    },
    buttonDelete: {
      type: Boolean,
      default: false,
    },
    disableEditButton: {
      type: Boolean,
      default: false,
    },
    disableDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {},
      params: {},
    };
  },
  computed: {
    ...mapState(["porPagina", "alturaTabla"]),
    // Creador de parametros de filtro
    filtersWithValues() {
      return Object.keys(this.filters).reduce((obj, key) => {
        const value = this.filters[key];
        if (value) return { ...obj, [key]: value };
        return obj;
      }, {});
    },
  },
  methods: {
    onPage(e) {
      this.params = {
        ...this.params,
        rows: e.rows,
        page: e.page + 1,
      };
      this.$emit("generate-page", this.params);
    },
    onSort(e) {
      this.params = {};

      this.params = {
        order: e.sortField,
        direction: e.sortOrder,
        rows: e.rows,
        filters: this.filtersWithValues,
      };
      this.$emit("generate-page", this.params);
    },
    onFilter() {
      this.params = {};

      this.params = {
        rows: this.$refs.dt.rows,
        filters: this.filtersWithValues,
      };
      this.$emit("generate-page", this.params);
    },
    editar(data) {
      if (this.disableEditButton) return;

      this.$emit("editar", data);
    },
    async eliminar(data) {
      if (this.disableDeleteButton) return;

      const res = await this.$alerts.eliminarRegistro();

      if (res.isConfirmed) {
        this.$emit("eliminar", data);
      }
    },
  },
};
</script>

<style>
.p-datatable-wrapper {
  height: v-bind(alturaTabla + "px");
}
</style>
