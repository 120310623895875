<template>
  <div class="text-right">
    <span>
      {{ $common.currencyEncode(value, "") }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
