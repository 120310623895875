<template>
  <mensaje-invalidos class="m-0 mt-3" v-if="v$.cliente.$error" />
  <div class="formgrid grid mt-5">
    <div class="col-6">
      <p-float-label label="Tipo documento" required>
        <dropdown-element
          v-model="v$.cliente.IdTipoDocumento.$model"
          :loading="isLoading"
          :options="listaTipoDocumento"
          optionLabel="Descripcion"
          optionValue="Id"
          filter
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdTipoDocumento?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-4">
      <p-float-label label="NIT" required>
        <input-text
          @blur="dvGenerator"
          v-model="v$.cliente.Nit.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.Nit?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-2">
      <p-float-label label="Dv">
        <input-text v-model="v$.cliente.Dv.$model" type="text" disabled />
      </p-float-label>
    </div>
    <div class="col-6 mt-5 field-checkbox">
      <check-box
        v-model="v$.cliente.EsJuridica.$model"
        id="juridica"
        :binary="true"
        :disabled="!userCanEdit"
      />
      <label for="juridica">Es jurídica</label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Regimen" required>
        <dropdown-element
          v-model="v$.cliente.IdRegimen.$model"
          :options="listaRegimen"
          :loading="isLoading"
          optionLabel="Descripcion"
          optionValue="Id"
          filter
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdRegimen?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Razón social" required>
        <input-text
          @blur="autocompletar"
          v-model="v$.cliente.RazonSocial.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.RazonSocial?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Nombre comercial" required>
        <input-text
          v-model="v$.cliente.NombreComercial.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.NombreComercial?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Alias">
        <input-text
          v-model="v$.cliente.Alias.$model"
          type="text"
          :disabled="!userCanEdit"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="País" required>
        <dropdown-element
          v-model="v$.cliente.IdPais.$model"
          :options="listaPais"
          :loading="isLoading"
          optionLabel="Descripcion"
          optionValue="Id"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdPais?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="Ciudad" required>
        <dropdown-element
          v-model="v$.cliente.IdCiudad.$model"
          :options="ciudades"
          optionLabel="Descripcion"
          optionValue="Id"
          :loading="isLoading"
          filter
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdCiudad?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Dirección" required>
        <input-text
          v-model="v$.cliente.Direccion.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.Direccion?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="Celular" required>
        <input-text
          v-model="v$.cliente.Celular.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.Celular?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="Teléfono">
        <input-text
          v-model="v$.cliente.Telefono.$model"
          type="text"
          :disabled="!userCanEdit"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-5">
      <p-float-label label="Email" required>
        <input-text
          v-model="v$.cliente.EmailFacturacion.$model"
          type="text"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.EmailFacturacion?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="Estado" required>
        <dropdown-element
          v-model="v$.cliente.IdEstado.$model"
          :loading="isLoading"
          :options="listaEstados"
          optionLabel="Descripcion"
          optionValue="Id"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdEstado?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-3 mt-5">
      <p-float-label label="Categoría" required>
        <dropdown-element
          v-model="v$.cliente.IdCategoria.$model"
          :loading="isLoading"
          :options="listaCategoria"
          optionLabel="Descripcion"
          optionValue="Id"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.IdCategoria?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div v-if="esEdicion" class="col-6 mt-5">
      <file-uploader
        label="Logo"
        :value="cliente.Logo"
        :disabled="!userCanEdit"
        @clean-file="onCleanFile"
        @change-file="onChangeFile"
      />
    </div>
    <div v-if="esEdicion" class="col-6 mt-5 field-checkbox">
      <check-box
        v-model="v$.cliente.MostrarLogo.$model"
        id="mostrarLogo"
        :binary="true"
        :disabled="cliente.Logo == null || !userCanEdit"
      />
      <label for="mostrarLogo">Mostrar logo</label>
    </div>
    <div class="col-6 mt-5">
      <uploader-url
        label="Página web"
        :url="cliente.PaginaWeb"
        :validation="esPaginaWeb"
      >
        <input-text
          v-model="v$.cliente.PaginaWeb.$model"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.PaginaWeb?.$invalid,
          }"
        />
      </uploader-url>
    </div>
    <div class="col-6 mt-5">
      <uploader-url
        label="Repositorio"
        :url="cliente.RepositorioGD"
        :validation="esRepositorio"
      >
        <input-text
          v-model="v$.cliente.RepositorioGD.$model"
          :disabled="!userCanEdit"
          :class="{
            'p-invalid': v$?.cliente?.RepositorioGD?.$invalid,
          }"
        />
      </uploader-url>
    </div>
    <div v-if="esEdicion" class="col-6 mt-5">
      <p-float-label label="Fecha creación">
        <input-text v-model="cliente.FechaCreacion" type="text" disabled />
      </p-float-label>
    </div>
    <div v-if="esEdicion" class="col-6 mt-5">
      <p-float-label label="Usuario ult. modificación">
        <input-text
          v-model="cliente.UsuarioModificacion"
          type="text"
          disabled
        />
      </p-float-label>
    </div>
    <div v-if="esEdicion" class="col-6 mt-5">
      <p-float-label label="Fecha ult. modificación">
        <input-text v-model="cliente.FechaModificacion" type="text" disabled />
      </p-float-label>
    </div>
    <div class="col-12 mt-5">
      <p-float-label label="Comentario">
        <text-area
          v-model="v$.cliente.Comentario.$model"
          rows="4"
          :disabled="!userCanEdit"
        />
      </p-float-label>
    </div>
  </div>
  <button-element
    label="Guardar"
    class="w-full my-5"
    :disabled="!userCanEdit"
    @click="formSubmit"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FileUploader from "@/components/FileUploader.vue";
import UploaderUrl from "@/components/UploaderUrl.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { dv } from "@/helpers/dv";
import { getCbx } from "@/services/cbx";

export default {
  components: {
    FileUploader,
    UploaderUrl,
  },
  props: {
    datos: Object,
  },
  emits: ["formSubmit"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      cliente: {
        EsJuridica: false,
        MostrarLogo: false,
        IdEstado: 1,
        IdCategoria: 1,
      },
      ciudades: [],
    };
  },
  computed: {
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaRegimen: "cbx/getRegimen",
      listaTipoDocumento: "cbx/getTipoDocumento",
      listaPais: "cbx/getPais",
      listaCategoria: "cbx/getCategoria",
      userCanEdit: "auth/userCanEdit",
    }),
    ...mapState("cbx", ["isLoading"]),
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    esPaginaWeb() {
      if (!this.cliente.PaginaWeb) return false;
      if (this.v$?.cliente?.PaginaWeb?.$invalid) return false;
      return true;
    },
    esRepositorio() {
      if (!this.cliente.RepositorioGD) return false;
      if (this.v$?.cliente?.RepositorioGD?.$invalid) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      actualizarLogo: "logo/actualizar",
      eliminarLogo: "logo/eliminar",
    }),
    async onChangeFile({ target }) {
      const [file] = target.files;
      const fileData = new FormData();
      fileData.append("file", file);
      const payload = { logo: fileData, clienteId: this.cliente.Id };
      const data = await this.actualizarLogo(payload);
      if (data) {
        this.cliente.Logo = data.logo;
        this.$toast.add(this.$alerts.logoCargado);
      }
    },
    async onCleanFile() {
      if (!this.cliente.Logo) return;
      const res = await this.eliminarLogo(this.cliente.Id);
      if (res) {
        this.cliente.Logo = null;
        this.cliente.MostrarLogo = false;
        this.$toast.add(this.$alerts.logoEliminado);
      }
    },
    dvGenerator({ target }) {
      const { value } = target;
      const nuevoDv = dv(value);
      this.cliente.Dv = nuevoDv;
    },
    async formSubmit() {
      if (!this.userCanEdit) return;

      const isFormCorrect = await this.v$.cliente.$validate();
      if (!isFormCorrect) return;

      this.$emit("formSubmit", this.cliente);
    },
    resetFormulario() {
      this.v$.$reset();
    },
    autocompletar({ target }) {
      if (this.esEdicion) return;
      const { value } = target;
      if (!this.cliente.NombreComercial) this.cliente.NombreComercial = value;
      if (!this.cliente.Alias) this.cliente.Alias = value;
    },
    async setCiudades(idPais) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await getCbx("ciudades", {
        pais: idPais,
      });
      this.$store.commit("setIsLoading", false);
      if (status == 200) this.ciudades = data;
      else this.$alertStatus(status, data);
    },
  },
  validations() {
    return {
      cliente: {
        Nit: { required },
        RazonSocial: { required },
        NombreComercial: { required },
        Direccion: { required },
        Celular: { required },
        EmailFacturacion: { required, email },
        IdEstado: { required },
        IdCategoria: { required },
        IdRegimen: { required },
        IdTipoDocumento: { required },
        IdCiudad: { required },
        IdPais: { required },
        PaginaWeb: { url },
        RepositorioGD: { url },
        Dv: {},
        Alias: {},
        Comentario: {},
        EsJuridica: {},
        Telefono: {},
        MostrarLogo: {},
      },
    };
  },
  watch: {
    datos: {
      handler() {
        this.cliente = { ...this.datos };
      },
    },
    "cliente.IdPais": {
      handler(val) {
        if (val) this.setCiudades(val);
      },
    },
  },
};
</script>
