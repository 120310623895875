import { getItem } from "@/helpers/storageHandler";

const state = {
  dbPais: getItem("gBaseDatos") || { IdT: "CO", Descripcion: "Colombia" },
};

const getters = {
  getPais: (state) => state.dbPais,
};

const mutations = {
  setPais: (state, data) => (state.dbPais = data),
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
