import {
  buscarTipoContrato,
  crearTipoContrato,
  actualizarTipoContrato,
  listarTipoContrato,
} from "@/services/tipoContrato";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  setData: (state, data) => (state.data = data),
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarTipoContrato(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async buscar({ commit }, id) {
    if (!id) id = 0;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await buscarTipoContrato(id);
    commit("setIsLoading", false, { root: true });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async crear({ commit }, tipoContrato) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearTipoContrato(tipoContrato);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? true : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, tipoContrato) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarTipoContrato(tipoContrato);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? true : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
