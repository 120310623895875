<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de atributos</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="abrirAtributoModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :empty="emptyMsg"
    :totalRecords="total"
    :sortable="true"
    :disableEditButton="!userCanEdit"
    @editar="preEditar"
    @generatePage="listar"
  >
    <template #IdTEstado="{ data, value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: data.ColorEstado }"
        >{{ value }}</span
      >
    </template>
    <template #Requerido="{ data }">
      <i
        :class="data.Requerido ? 'pi pi-check' : 'pi pi-times'"
        :style="data.Requerido ? 'color: green' : 'color: red'"
      ></i>
    </template>
  </table-data-lazy>
  <!-- Modales -->
  <atributo-modal
    ref="atributoModal"
    v-model:datos="atributo"
    v-model="abrirAtributoModal"
    @crear-atributo="crearAtributo"
    @editar-atributo="actualizarAtributo"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import AtributoModal from "@/components/maestros/atributos/AtributoModal.vue";

export default {
  components: { TableDataLazy, AtributoModal },
  data() {
    return {
      atributo: {},
      columns: [],
      emptyMsg: "No se encontraron atributos...",
      abrirAtributoModal: false,
    };
  },
  computed: {
    ...mapGetters({
      lista: "atributos/getLista",
      total: "atributos/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
      { field: "IdTTipoCampo", header: "Tipo campo" },
      { field: "IdTEstado", header: "Estado" },
      { field: "Requerido", header: "Requerido" },
    ];
  },
  async mounted() {
    await this.listar();
    this.getTipoCampo();
  },
  methods: {
    ...mapActions({
      listar: "atributos/listar",
      crear: "atributos/crear",
      actualizar: "atributos/actualizar",
      getTipoCampo: "cbx/getCbxTipoCampo",
    }),
    ...mapMutations({
      añadirCreado: "atributos/setCreado",
      añadirActualizado: "atributos/setActualizado",
    }),
    async crearAtributo(datos) {
      const data = await this.crear(datos);
      if (data) {
        this.añadirCreado(data);
        this.$refs.atributoModal.resetModal();
        this.$toast.add(this.$alerts.atributoCreado);
      }
    },
    async actualizarAtributo(datos) {
      const data = await this.actualizar(datos);
      if (data) {
        this.añadirActualizado(data);
        this.$refs.atributoModal.resetModal();
        this.$toast.add(this.$alerts.atributoActualizado);
      }
    },
    preEditar(data) {
      this.atributo = data;
      this.abrirAtributoModal = true;
    },
  },
};
</script>
