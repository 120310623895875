<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de compañías</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEditGarantto"
        @click="abrirModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :totalRecords="total"
    :empty="emptyMsg"
    :sortable="true"
    :buttonDelete="userCanEditGarantto"
    @editar="preEditar"
    @generatePage="listar"
    @eliminar="(data) => eliminar(data.Id)"
  />
  <!-- Modal -->
  <g-companias-modal
    v-model="abrirModal"
    v-model:datos="compania"
    @crear="preEditar"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import GCompaniasModal from "../components/GCompaniasModal.vue";
import { buscarCompania } from "../services/gcompanias";

export default {
  components: { TableDataLazy, GCompaniasModal },
  data() {
    return {
      compania: {},
      columns: [],
      abrirModal: false,
      emptyMsg: "No se encontraron compañías...",
    };
  },
  computed: {
    ...mapGetters({
      lista: "gcompanias/getLista",
      total: "gcompanias/getTotal",
      userCanEditGarantto: "auth/userCanEditGarantto",
      dbPais:"gpais/getPais",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "Id", header: "Id" },
      { field: "NombreComercial", header: "Nombre comercial" },
      { field: "Direccion", header: "Dirección" },
      { field: "Ciudad", header: "Ciudad" },
      { field: "Telefono", header: "Teléfono" },
      { field: "WhatsApp", header: "WhatsApp" },
      { field: "Licencia", header: "Licencia" },
      { field: "MarcaAutorizada", header: "Marcas autorizadas" },
      { field: "MarcaNoAutorizada", header: "Marcas no autorizadas" },
      { field: "Estado", header: "Estado" },
      { field: "Comentario", header: "Comentarios" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "gcompanias/listar",
      eliminar: "gcompanias/eliminar",
      getCbxGaranttoMarca: "cbx/getCbxGaranttoMarca",
      getCbxGaranttoEstado: "cbx/getCbxGaranttoEstado",
      getCbxGaranttoMarcaCategoria: "cbx/getCbxGaranttoMarcaCategoria",
    }),
    async preEditar(item) {
      if (item) {
        this.$store.commit("setIsLoading", true);
        const { data, status } = await buscarCompania(item.Id);
        this.$store.commit("setIsLoading", false);
        if (status == 200) {
          this.compania = data;
          this.abrirModal = true;
        } else this.$alertStatus(status, data);
      }
    },
  },
  watch: {
    async dbPais() {
      await this.listar()
      await this.getCbxGaranttoMarca()
      await this.getCbxGaranttoEstado()
      await this.getCbxGaranttoMarcaCategoria()
    }
  }
};
</script>
