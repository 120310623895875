<template>
  <span class="p-input-icon-left mr-2">
    <i class="pi pi-search" />
    <input-text
      type="text"
      :placeholder="placeholder"
      @keydown.enter="onSearch"
    />
  </span>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Buscar",
    },
  },
  data() {
    return {
      oldValue: null,
    };
  },
  methods: {
    onSearch({ target: { value } }) {
      if (this.oldValue === null && value === "") return;
      if (value == this.oldValue) return;
      this.oldValue = value;
      this.$emit("search", value);
    },
  },
};
</script>
