<template>
  <div class="col-10">
    <Card-element>
      <template #header>
        <div
          style="background-color: #1e1e1e"
          class="text-electronic w-full flex align-items-center justify-content-between px-4 py-0 m-0"
        >
          <p class="my-3">Documentos electrónicos por año:</p>
          <span>{{ documentosMes * 12 }}</span>
        </div>
      </template>

      <template #content>
        <ul>
          <li v-show="documentos?.facturasVentas">
            <div
              class="w-full flex align-items-center justify-content-between list-electronic"
            >
              <span>Facturas de ventas</span>
              <i class="pi pi-check py-2 px-3"></i>
            </div>
          </li>
          <li v-show="documentos?.notasCredito">
            <div
              class="w-full flex align-items-center justify-content-between list-electronic py-1"
            >
              <span>Notas crédito y débito</span>
              <i class="pi pi-check py-1 px-3"></i>
            </div>
          </li>
          <li v-show="documentos?.documentoSoporte">
            <div
              class="w-full flex align-items-center justify-content-between list-electronic py-1"
            >
              <span>Documento soporte</span>
              <i class="pi pi-check py-1 px-3"></i>
            </div>
          </li>
          <li v-show="documentos?.empleados">
            <div
              class="w-full flex align-items-center justify-content-between list-electronic py-1"
            >
              <span>Nómina electrónica</span>
              <i class="pi pi-check py-1 px-3"></i>
            </div>
          </li>
          <li v-show="documentos?.acuses">
            <div
              class="w-full flex align-items-center justify-content-between list-electronic py-1"
            >
              <span>Acuses a facturas de compra</span>
              <i class="pi pi-check py-1 px-3"></i>
            </div>
          </li>
          <li>
            <div
              class="w-full flex align-items-center justify-content-between list-electronic pb-2"
            >
              <span style="color: #a203dd">Firma electrónica</span>
              <i class="pi pi-check py-1 px-3"></i>
            </div>
          </li>
        </ul>
      </template>
    </Card-element>
  </div>
</template>
<script>
export default {
  props: {
    documentos: {
      type: Object,
      default: () => ({}),
    },
    documentosMes: Number,
  },
};
</script>
