<template>
  <input-chips
    :modelValue="value"
    separator=","
    addOnBlur
    :class="{
      'p-invalid': invalid,
    }"
    :disabled="disabled"
    @add="onAdd"
    @remove="onRemove"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value() {
      return this.modelValue?.split(",") ?? [];
    },
  },
  methods: {
    onAdd({ value }) {
      this.$emit("update:modelValue", value?.join(",") ?? null);
    },
    onRemove({ value }) {
      const arr = this.modelValue?.split(",") ?? [];
      const i = arr.findIndex((e) => e == value);
      arr.splice(i, 1);
      this.onAdd({ value: arr?.length > 0 ? arr : null });
    },
  },
};
</script>
