<template>
  <button-element
    icon="pi pi-ellipsis-v"
    class="p-button-text text-gray-900"
    @click="(e) => $refs.menu.toggle(e)"
  />
  <menu-element id="menu" ref="menu" :model="options" :popup="true" />
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
