<template>
  <data-table
    :value="options"
    paginator
    stripedRows
    :rows="porPaginaTabview"
    :rowsPerPageOptions="rowsPerPageOptions"
    v-model:filters="mutableFilters"
    filterDisplay="row"
    responsiveLayout="stack"
    breakpoint="767px"
    currentPageReportTemplate="{first} - {last} de {totalRecords} resultados"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
  >
    <template #empty> {{ empty }} </template>
    <column-element style="width: 46px">
      <template #body="{ data }">
        <div class="flex">
          <button-element
            icon="pi pi-eye"
            class="p-button-success p-button-rounded py-1"
            :disabled="disableEditButton"
            @click="editar(data)"
          />
          <button-element
            v-if="buttonDelete"
            icon="pi pi-trash"
            class="p-button-danger p-button-rounded py-1 ml-1"
            :disabled="disableDeleteButton"
            @click="eliminar(data)"
          />
        </div>
      </template>
    </column-element>
    <column-element
      v-for="col of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field"
      bodyStyle="white-space:nowrap;"
    >
      <template #filter="{ filterModel, filterCallback }">
        <input-text
          type="text"
          v-model="filterModel.value"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #body="{ data }">
        <slot :value="data[col.field]" :data="data" :name="col.field">{{
          data[col.field]
        }}</slot>
      </template>
    </column-element>
  </data-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    empty: {
      type: String,
      required: true,
    },
    buttonDelete: {
      type: Boolean,
      default: false,
    },
    disableEditButton: {
      type: Boolean,
      default: false,
    },
    disableDeleteButton: {
      type: Boolean,
      default: false,
    },
    showRowsPerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableFilters: this.filters,
    };
  },
  computed: {
    ...mapGetters({
      porPaginaTabview: "porPaginaTabview",
    }),
    rowsPerPageOptions() {
      if (this.showRowsPerPage) {
        const opt = [this.porPaginaTabview, 50, 100, 200, 500];
        return [...new Set(opt)].sort((a, b) => a - b);
      } else {
        return null;
      }
    },
  },
  methods: {
    editar(data) {
      if (this.disableEditButton) return;

      this.$emit("abrirEditar", data);
    },
    async eliminar(data) {
      if (this.disableDeleteButton) return;

      const res = await this.$alerts.eliminarRegistro();

      if (res.isConfirmed) {
        this.$emit("eliminar", data);
      }
    },
  },
};
</script>
