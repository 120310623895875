import {
  buscarCliente,
  crearCliente,
  actualizarCliente,
  listarCliente,
} from "@/services/clientes";

const state = {
  data: { data: [], total: 0 },
  contactos: [],
  sedes: [],
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
  getContactos: (state) => state.contactos,
  getSedes: (state) => state.sedes,
  getContactoPrincipal: ({ contactos }) =>
    contactos.find((el) => el.Principal === true),
  getSedePrincipal: ({ sedes }) => sedes.find((el) => el.EsPrincipal === true),
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setContactos: (state, data) => (state.contactos = data),
  setContactosAdd: (state, data) => state.contactos.push(data),
  setContactosEdit: (state, data) => {
    state.contactos.forEach((el, i, array) => {
      if (el.IdClienteContacto == data.IdClienteContacto) array[i] = data;
    });
  },
  setContactosDelete: (state, id) => {
    state.contactos.forEach((el, i, array) => {
      if (el.IdClienteContacto == id) array.splice(i, 1);
    });
  },
  setSedes: (state, data) => (state.sedes = data),
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarCliente(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async buscar({ commit }, id) {
    if (!id) id = 0;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await buscarCliente(id);
    commit("setIsLoading", false, { root: true });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async crear({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearCliente(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, { datos, id }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarCliente(datos, id);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
