<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.atributo.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <p-float-label label="Código" required>
          <input-text
            type="text"
            v-model="v$.atributo.IdT.$model"
            :class="{
              'p-invalid': v$?.atributo?.IdT?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Descripción" required>
          <input-text
            type="text"
            v-model="v$.atributo.Descripcion.$model"
            :class="{
              'p-invalid': v$?.atributo?.Descripcion?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Tipo campo" required>
          <dropdown-element
            v-model="v$.atributo.IdTipoCampo.$model"
            :loading="isLoading"
            :options="listaTipoCampo"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.atributo?.IdTipoCampo?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Estado" required>
          <dropdown-element
            v-model="v$.atributo.IdEstado.$model"
            :loading="isLoading"
            :options="listaEstados"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.atributo?.IdEstado?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="field-checkbox col-12 mt-5">
        <check-box
          v-model="v$.atributo.Requerido.$model"
          id="requerido"
          :binary="true"
        />
        <label for="requerido">Atributo requerido</label>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
    datos: Object,
  },
  data() {
    return {
      atributo: {
        IdEstado: 1,
        IdTipoCampo: 1,
        Requerido: false,
      },
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaTipoCampo: "cbx/getTipoCampo",
      userCanEdit: "auth/userCanEdit",
    }),
    title() {
      if (this.esEdicion) return "Editar atributo";
      return "Crear atributo";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
  },
  methods: {
    async cerrarModal() {
      const formHasChanged = this.v$.atributo.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.atributo.$validate();
      if (!isFormCorrect) return;
      if (!this.esEdicion) this.$emit("crearAtributo", this.atributo);
      else this.$emit("editarAtributo", this.atributo);
    },
    resetModal() {
      this.atributo = {
        IdEstado: 1,
        IdTipoCampo: 1,
        Requerido: false,
      };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
  },
  validations() {
    return {
      atributo: {
        IdEstado: { required },
        IdTipoCampo: { required },
        IdT: { required },
        Descripcion: { required },
        Requerido: {},
      },
    };
  },
  watch: {
    datos: {
      handler() {
        if (this.datos === null) return;
        this.atributo = { ...this.datos };
      },
    },
  },
};
</script>
