import PFloatLabel from "@/components/PFloatLabel.vue";
import ModalWrapper from "@/components/ModalWrapper.vue";
import ButtonOptions from "@/components/ButtonOptions.vue";
import CurrencyColumn from "@/components/CurrencyColumn.vue";

const install = (app) => {
  //Registro de componentes
  app.component("PFloatLabel", PFloatLabel);
  app.component("ModalWrapper", ModalWrapper);
  app.component("ButtonOptions", ButtonOptions);
  app.component("CurrencyColumn", CurrencyColumn);
};
export default install;
