import gmarcas from "../marcas/store/gmarcas";
import gcompanias from "../companias/store/gcompanias";
import gproductos from "../productos/store/gproductos";
import gpais from "./dbPais";

export const GaranttoStores = {
  gmarcas,
  gcompanias,
  gproductos,
  gpais,
};
