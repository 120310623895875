<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.sede.$error" />
    <div class="formgrid grid my-5">
      <div class="col-6">
        <p-float-label label="Celular" required>
          <input-text
            type="text"
            v-model="v$.sede.Celular.$model"
            :class="{
              'p-invalid': v$?.sede?.Celular?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6">
        <p-float-label label="Dirección" required>
          <input-text
            type="text"
            v-model="v$.sede.Direccion.$model"
            :class="{
              'p-invalid': v$?.sede?.Direccion?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Ciudad" required>
          <dropdown-element
            v-model="v$.sede.IdCiudad.$model"
            :loading="isLoading"
            :options="ciudades"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.sede?.IdCiudad?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="País" required>
          <dropdown-element
            v-model="v$.sede.IdPais.$model"
            :loading="isLoading"
            :options="listaPais"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.sede?.IdPais?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Estado" required>
          <dropdown-element
            v-model="v$.sede.IdEstado.$model"
            :loading="isLoading"
            :options="listaEstados"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.sede?.IdEstado?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="field-checkbox col-6 mt-5">
        <check-box
          id="principal"
          v-model="v$.sede.EsPrincipal.$model"
          :binary="true"
        />
        <label for="principal">Sede principal</label>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { getCbx } from "@/services/cbx";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
    datos: Object,
  },
  data() {
    return {
      sede: {
        EsPrincipal: false,
        IdEstado: 1,
      },
      ciudades: [],
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaPais: "cbx/getPais",
      userCanEdit: "auth/userCanEdit",
    }),
    title() {
      if (this.esEdicion) return "Editar sede";
      return "Crear sede";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
  },
  methods: {
    async cerrarModal() {
      const formHasChanged = this.v$.sede.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.sede.$validate();
      if (!isFormCorrect) return;
      if (!this.esEdicion) this.$emit("crearSede", this.sede);
      else this.$emit("editarSede", this.sede);
    },
    resetModal() {
      this.sede = {
        EsPrincipal: false,
        IdEstado: 1,
      };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async setCiudades(idPais) {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await getCbx("ciudades", {
        pais: idPais,
      });
      this.$store.commit("setIsLoading", false);
      if (status == 200) this.ciudades = data;
      else this.$alertStatus(status, data);
    },
  },
  validations() {
    return {
      sede: {
        IdEstado: { required },
        IdPais: { required },
        IdCiudad: { required },
        Direccion: { required },
        Celular: { required },
        EsPrincipal: {},
      },
    };
  },
  watch: {
    datos: {
      handler() {
        if (this.datos === null) return;
        this.sede = { ...this.datos };
      },
    },
    "sede.IdPais": {
      handler(val) {
        if (val) this.setCiudades(val);
      },
    },
  },
};
</script>
