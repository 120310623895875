<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    :validador="v$"
    @cerrar-modal="resetModal"
    @guardar-modal="guardarModal"
  >
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.form.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <p-float-label label="Código">
          <input-text type="text" v-model="v$.form.IdT.$model" />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Descripción" required>
          <input-text
            type="text"
            v-model="v$.form.Descripcion.$model"
            :class="{
              'p-invalid': v$?.form?.Descripcion?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Departamento" required>
          <input-text
            type="text"
            v-model="v$.form.Estado.$model"
            :class="{
              'p-invalid': v$?.form?.Estado?.$error,
            }"
          />
        </p-float-label>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  setup() {
    return {
      v$: useVuelidate({ $stopPropagation: true }),
    };
  },
  props: {
    datos: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters({ userCanEdit: "auth/userCanEdit" }),
    title() {
      if (this.esEdicion) return "Editar ciudad";
      return "Crear ciudad";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
  },
  methods: {
    resetModal() {
      this.form = {};
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async guardarModal() {
      if (await this.v$.form.$validate()) {
        if (this.esEdicion) this.$emit("editar", this.form);
        else this.$emit("crear", this.form);
      }
    },
  },
  validations() {
    return {
      form: {
        IdT: {},
        Descripcion: { required },
        Estado: { required },
      },
    };
  },
  watch: {
    datos(valor) {
      if (valor) {
        this.form = { ...this.datos };
      }
    },
  },
};
</script>
