import axios from "./config";

export const listarPaises = async () => {
  const { data, status } = await axios.get("/paises").catch((e) => e.response);
  return { data, status };
};

export const buscarPais = async (idPais) => {
  const { data, status } = await axios
    .get(`/paises/${idPais}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearPais = async (pais) => {
  const { data, status } = await axios
    .post("/paises", pais)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarPais = async (pais) => {
  const { data, status } = await axios
    .put(`/paises/${pais.Id}`, pais)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarPais = async (idPais) => {
  const { data, status } = await axios
    .delete(`/paises/${idPais}`)
    .catch((e) => e.response);
  return { data, status };
};
