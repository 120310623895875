import { isLoading } from "../helpers";
import { listarContactos } from "@/services/contactos";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  add: ({ data: { data } }, item) => data.push(item),
  update: ({ data: { data } }, item) => {
    data.forEach((el, i) => {
      if (el.Id == item.Id) data[i] = item;
    });
  },
  setData: (state, data) => (state.data = data),
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    isLoading();
    const { data, status } = await listarContactos(params);
    isLoading(false);
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
