// Vistas
import SoporteCsa from "../csa/views/SoporteCsa.vue";
import SoporteCmms from "../cmms/views/SoporteCmms.vue";

export const SoporteRoutes = [
  {
    path: "/soporte/csa",
    component: SoporteCsa,
    meta: {
      title: "Soporte CSA",
    },
  },
  {
    path: "/soporte/cmms",
    component: SoporteCmms,
    meta: {
      title: "Soporte CMMS",
    },
  },
];
