import {
  crearCompania,
  actualizarCompania,
  eliminarCompania,
  listarCompania,
} from "../services/gcompanias";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setCreated: ({ data }, compania) => data.data.unshift(compania),
  setUpdated: ({ data }, compania) => {
    const index = data.data.findIndex((e) => e.Id == compania.Id);
    data.data[index] = compania;
  },
  setDeleted: ({ data }, idCompania) => {
    const index = data.data.findIndex((e) => e.Id == idCompania);
    data.data.splice(index, 1);
  },
  setAddLicense: ({ data }, { idCompania, licencia }) => {
    const index = data.data.findIndex((compania) => compania.Id === idCompania);
    if (index !== -1) data.data[index].Licencia = licencia
  }
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarCompania(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async crear({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearCompania(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 201
      ? commit("setCreated", data)
      : this.$alertStatus(status, data);

    return { data, status };
  },
  async actualizar({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarCompania(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 200
      ? commit("setUpdated", data)
      : this.$alertStatus(status, data);

    return status;
  },
  async eliminar({ commit }, idCompania) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await eliminarCompania(idCompania);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 204
      ? commit("setDeleted", idCompania)
      : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
