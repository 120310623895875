import {
  crearAtributo,
  actualizarAtributo,
  listarAtributo,
} from "@/services/atributos";

const state = {
  data: { data: [], total: 0 },
};

const getters = {
  getLista: (state) => state.data.data,
  getTotal: (state) => state.data.total,
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setCreado: ({ data }, atributo) => data.data.push(atributo),
  setActualizado: ({ data }, atributo) => {
    data.data = data.data.map((element) =>
      element.Id == atributo.Id ? atributo : element
    );
  },
};

const actions = {
  async listar({ commit, rootState }, params = {}) {
    if (!params?.rows) params.rows = rootState.porPagina;
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarAtributo(params);
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async crear({ commit }, atributo) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearAtributo(atributo);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, atributo) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarAtributo(atributo);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
