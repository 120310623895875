import axios from "./config";

export const listarCliente = async (params) => {
  const { data, status } = await axios
    .get("/cliente", { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarCliente = async (id) => {
  const { data, status } = await axios
    .get(`/cliente/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearCliente = async (cliente) => {
  const { data, status } = await axios
    .post("/cliente", cliente)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarCliente = async (cliente, id) => {
  const { data, status } = await axios
    .put(`/cliente/${id}`, cliente)
    .catch((e) => e.response);
  return { data, status };
};

export const generarExcelCliente = async () => {
  const { data, status, headers } = await axios
    .get("/cliente/excel", { responseType: "blob" })
    .catch((e) => e.response);
  return { data, status, headers };
};
