// Vistas
import GProductosView from "../productos/views/GProductosView.vue";
import GMarcasView from "../marcas/views/GMarcasView.vue";
import GCompaniasView from "../companias/views/GCompaniasView.vue";

export const GaranttoRoutes = [
  {
    name: "garantto-productos",
    path: "/garantto/:dbPais/productos",
    component: GProductosView,
    meta: {
      title: "Productos Garantto",
    },
  },
  {
    name: "garantto-marcas",
    path: "/garantto/:dbPais/marcas",
    component: GMarcasView,
    meta: {
      title: "Marcas Garantto",
    },
  },
  {
    name: "garantto-companias",
    path: "/garantto/:dbPais/companias",
    component: GCompaniasView,
    meta: {
      title: "Compañías Garantto",
    },
  },
];
