<template>
  <toolbar-element class="submenu-tabview">
    <template #start>
      <h3 class="m-0">Lista de contratos</h3>
    </template>
    <template #end>
      <button-element
        label="Nuevo contrato"
        icon="pi pi-plus"
        class="p-button-success"
        :disabled="!userCanEdit"
        @click="$emit('abrirCrear')"
      />
    </template>
  </toolbar-element>
  <table-component
    :options="contratos"
    :columns="columns"
    :filters="filters"
    :empty="emptyMsg"
    showRowsPerPage
    @abrir-editar="abrirEditar"
  >
    <template #IdTEstado="{ data, value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: data.ColorEstado }"
        >{{ value }}</span
      >
    </template>
    <template #FacturacionAnticipada="{ data }">
      <i
        :class="data.FacturacionAnticipada ? 'pi pi-check' : 'pi pi-times'"
        :style="data.FacturacionAnticipada ? 'color: green' : 'color: red'"
      ></i>
    </template>
    <template #Valor="{ value }">
      <currency-column :value="value" />
    </template>
  </table-component>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";

export default {
  components: { TableComponent },
  props: {
    contratos: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["abrirEditar", "abrirCrear"],
  data() {
    return {
      columns: [
        { field: "IdT", header: "Código" },
        { field: "Producto", header: "Producto" },
        { field: "Valor", header: "Valor" },
        { field: "TipoContrato", header: "Tipo contrato" },
        { field: "FechaInicioContrato", header: "Fecha inicio" },
        { field: "FechaSiguienteFactura", header: "Siguiente factura" },
        { field: "FacturacionAnticipada", header: "Fact. anticipada" },
        { field: "IdTEstado", header: "Estado" },
        { field: "Observaciones", header: "Observaciones" },
      ],
      filters: {
        IdT: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Producto: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Valor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        TipoContrato: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FechaInicioContrato: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        FechaSiguienteFactura: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },

        FacturacionAnticipada: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        IdTEstado: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Observaciones: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      emptyMsg: "No se han encontrado contratos...",
    };
  },
  computed: {
    ...mapGetters({
      userCanEdit: "auth/userCanEdit",
    }),
  },
  methods: {
    abrirEditar(data) {
      this.$emit("abrirEditar", data);
    },
  },
};
</script>
