import { getCbx } from "@/services/cbx";
import store from "@/store/index"

const codigoPais = () => store.state.gpais.dbPais.IdT

const state = {
  estados: [],
  regimen: [],
  tipoDocumento: [],
  ciudad: [],
  pais: [],
  categoria: [],
  cargo: [],
  producto: [],
  tipoPago: [],
  tipoContrato: [],
  moneda: [],
  tipoCampo: [],
  atributo: [],
  usuarios: [],
  garanttoProducto: [],
  garanttoMarca: [],
  garanttoEstado: [],
  garanttoMarcaCategoria: {},
  garanttoBaseDatosMaestro: [],
  garanttoCiudadesMaestro: [],
  isLoading: false,
};

const getters = {
  getEstados1y2: (state) =>
    state.estados.filter((el) => [1, 2].includes(el.Id)),
  getEstados: (state) => state.estados,
  getRegimen: (state) => state.regimen,
  getTipoDocumento: (state) => state.tipoDocumento,
  getCiudad: (state) => state.ciudad,
  getPais: (state) => state.pais,
  getCategoria: (state) => state.categoria,
  getCargo: (state) => state.cargo,
  getProducto: (state) => state.producto,
  getTipoPago: (state) => state.tipoPago,
  getTipoContrato: (state) => state.tipoContrato,
  getMoneda: (state) => state.moneda,
  getTipoCampo: (state) => state.tipoCampo,
  getAtributo: (state) => state.atributo,
  getUsuarios: (state) => state.usuarios,
  getGaranttoProducto: (state) => state.garanttoProducto,
  getGaranttoMarca: (state) => state.garanttoMarca,
  getGaranttoEstado: (state) => state.garanttoEstado,
  getGaranttoMarcaCategoria: (state) => state.garanttoMarcaCategoria,
  getGaranttoBasedatosMaestro: (state) => state.garanttoBaseDatosMaestro,
  getGaranttoCiudadesMaestro: (state) => state.garanttoCiudadesMaestro,
};

const mutations = {
  setEstados: (state, data) => (state.estados = data),
  setRegimen: (state, data) => (state.regimen = data),
  setTipoDocumento: (state, data) => (state.tipoDocumento = data),
  setCiudad: (state, data) => (state.ciudad = data),
  setPais: (state, data) => (state.pais = data),
  setCategoria: (state, data) => (state.categoria = data),
  setCargo: (state, data) => (state.cargo = data),
  setProducto: (state, data) => (state.producto = data),
  setTipoPago: (state, data) => (state.tipoPago = data),
  setTipoContrato: (state, data) => (state.tipoContrato = data),
  setMoneda: (state, data) => (state.moneda = data),
  setTipoCampo: (state, data) => (state.tipoCampo = data),
  setAtributo: (state, data) => (state.atributo = data),
  setUsuarios: (state, data) => (state.usuarios = data),
  setGaranttoProducto: (state, data) => (state.garanttoProducto = data),
  setGaranttoMarca: (state, data) => (state.garanttoMarca = data),
  setGaranttoEstado: (state, data) => (state.garanttoEstado = data),
  setGaranttoMarcaCategoria: (state, data) =>
    (state.garanttoMarcaCategoria = data),
  setGaranttoBaseDatosMaestro: (state, data) =>
    (state.garanttoBaseDatosMaestro = data),
  setGaranttoCiudadesMaestro: (state, data) =>
    (state.garanttoCiudadesMaestro = data),
  setIsLoading: (state, toggle) => (state.isLoading = toggle),
};

const actions = {
  async getCbxEstados({ commit }) {
    const { data, status } = await getCbx("estados");
    status == 200
      ? commit("setEstados", data)
      : this.$alertStatus(status, data);
  },
  async getCbxRegimen({ commit }) {
    const { data, status } = await getCbx("regimen");
    status == 200
      ? commit("setRegimen", data)
      : this.$alertStatus(status, data);
  },
  async getCbxTipoDocumento({ commit }) {
    const { data, status } = await getCbx("tipodocumento");
    status == 200
      ? commit("setTipoDocumento", data)
      : this.$alertStatus(status, data);
  },
  async getCbxCiudad({ commit }, params = { pais: 1 }) {
    const { data, status } = await getCbx("ciudades", params);
    status == 200 ? commit("setCiudad", data) : this.$alertStatus(status, data);
  },
  async getCbxPais({ commit }) {
    const { data, status } = await getCbx("pais");
    status == 200 ? commit("setPais", data) : this.$alertStatus(status, data);
  },
  async getCbxCategoria({ commit }) {
    const { data, status } = await getCbx("categoria");
    status == 200
      ? commit("setCategoria", data)
      : this.$alertStatus(status, data);
  },
  async getCbxCargo({ commit }) {
    const { data, status } = await getCbx("cargo");
    status == 200 ? commit("setCargo", data) : this.$alertStatus(status, data);
  },
  async getCbxProducto({ commit }) {
    const { data, status } = await getCbx("producto");
    status == 200
      ? commit("setProducto", data)
      : this.$alertStatus(status, data);
  },
  async getCbxTipoPago({ commit }) {
    const { data, status } = await getCbx("tipopago");
    status == 200
      ? commit("setTipoPago", data)
      : this.$alertStatus(status, data);
  },
  async getCbxTipoContrato({ commit }) {
    const { data, status } = await getCbx("tipocontrato");
    status == 200
      ? commit("setTipoContrato", data)
      : this.$alertStatus(status, data);
  },
  async getCbxMoneda({ commit }) {
    const { data, status } = await getCbx("moneda");
    status == 200 ? commit("setMoneda", data) : this.$alertStatus(status, data);
  },
  async getCbxTipoCampo({ commit }) {
    const { data, status } = await getCbx("tipocampo");
    status == 200
      ? commit("setTipoCampo", data)
      : this.$alertStatus(status, data);
  },
  async getCbxAtributo({ commit }) {
    const { data, status } = await getCbx("atributo");
    status == 200
      ? commit("setAtributo", data)
      : this.$alertStatus(status, data);
  },
  async getCbxUsuarios({ commit }) {
    const { data, status } = await getCbx("usuarios");
    status == 200
      ? commit("setUsuarios", data)
      : this.$alertStatus(status, data);
  },

  // ############### GARANTTO ####################

  async getCbxGaranttoProducto({ commit }) {
    const gpais = codigoPais()
    const { data, status } = await getCbx(`garantto/${gpais}/producto`);
    status == 200
      ? commit("setGaranttoProducto", data)
      : this.$alertStatus(status, data);
  },
  async getCbxGaranttoMarca({ commit }) {
    const gpais = codigoPais()
    const { data, status } = await getCbx(`garantto/${gpais}/marca`);
    status == 200
      ? commit("setGaranttoMarca", data)
      : this.$alertStatus(status, data);
  },
  async getCbxGaranttoEstado({ commit }) {
    const gpais = codigoPais()
    const { data, status } = await getCbx(`garantto/${gpais}/estado`);
    status == 200
      ? commit("setGaranttoEstado", data)
      : this.$alertStatus(status, data);
  },
  async getCbxGaranttoMarcaCategoria({ commit }) {
    const gpais = codigoPais()
    const { data, status } = await getCbx(`garantto/${gpais}/marca-categoria`);
    if (status == 200) {
      const processedData = data.reduce((acc, { IdG_Marca, ...item }) => {
        if (!acc?.[IdG_Marca]) acc[IdG_Marca] = [];
        acc[IdG_Marca].push(item);
        return acc;
      }, {});
      commit("setGaranttoMarcaCategoria", processedData);
    } else {
      this.$alertStatus(status, data);
    }
  },

  // ############## GARANTTO MAESTRO #######################

  async getCbxGaranttoBaseDatosMaestro({ commit }) {
    const { data, status } = await getCbx("garantto/basedatos-maestro");
    if (status === 200) commit("setGaranttoBaseDatosMaestro", data) 
    else this.$alertStatus(status, data)
  },
  async getCbxGaranttoCiudadesMaestro({ commit }, params) {
    const { data, status } = await getCbx(`garantto/ciudades-maestro`, params)
    if (status === 200) commit("setGaranttoCiudadesMaestro", data)
    else this.$alertStatus(status, data)
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
