<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Tipos de contrato</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="tipoModalCrear = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :empty="emptyMsg"
    :totalRecords="total"
    :disableEditButton="!userCanEdit"
    @editar="abrirEditar"
    @generatePage="listar"
  />
  <!-- Modales -->
  <t-contrato-crear
    ref="tcontratoCrear"
    v-model="tipoModalCrear"
    @crear="crearTipoContrato"
  />
  <t-contrato-editar
    ref="tcontratoEditar"
    v-model="tipoModalEditar"
    :tipo-contrato="tipoContrato"
    @editar="editarTipoContrato"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import TContratoCrear from "@/components/maestros/tipos_contrato/TContratoCrear.vue";
import TContratoEditar from "@/components/maestros/tipos_contrato/TContratoEditar.vue";

export default {
  components: { TableDataLazy, TContratoCrear, TContratoEditar },
  data() {
    return {
      tipoContrato: {},
      columns: [],
      emptyMsg: "No se encontraron tipos de contrato...",
      tipoModalCrear: false,
      tipoModalEditar: false,
    };
  },
  computed: {
    ...mapState(["esMovil"]),
    ...mapGetters({
      lista: "tipoContrato/getLista",
      total: "tipoContrato/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
      { field: "FrecuenciaMes", header: "Frecuencia mensual" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "tipoContrato/listar",
      crear: "tipoContrato/crear",
      actualizar: "tipoContrato/actualizar",
    }),
    async crearTipoContrato(data) {
      const tcontratoCreado = await this.crear(data);
      if (tcontratoCreado) {
        await this.listar();
        this.$refs.tcontratoCrear.cerrarModal();
        this.$toast.add(this.$alerts.tcontratoCreado);
      }
    },
    async editarTipoContrato(data) {
      const tcontratoActualizado = await this.actualizar(data);
      if (tcontratoActualizado) {
        await this.listar();
        this.$refs.tcontratoEditar.cerrarModal();
        this.$toast.add(this.$alerts.tcontratoActualizado);
      }
    },
    abrirEditar(data) {
      this.tipoContrato = data;
      this.tipoModalEditar = true;
    },
  },
};
</script>
