import { getCookie } from "@/helpers";
import axios from "axios";

const axiosConfig = {
  baseURL: `${process.env?.VUE_APP_API_URL ?? location.origin}/api`,
  headers: {
    Accept: "application/json",
  },
};
const token = getCookie("authToken");

if (token) axiosConfig.headers.Authorization = `Bearer ${token}`;

const baseConfig = axios.create(axiosConfig);

export const install = (app) => {
  app.config.globalProperties.$http = baseConfig;
  app.config.globalProperties.$store.$http = baseConfig;
};
export default baseConfig;
