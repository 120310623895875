<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de productos</h3>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="abrirProductoModal = true"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :empty="emptyMsg"
    :totalRecords="total"
    :disableEditButton="!userCanEdit"
    @editar="preEditar"
    @generatePage="listar"
  >
    <template #Color="{ data }">
      <span class="color-span" :style="{ backgroundColor: data.Color }"></span>
    </template>
    <template #IdTEstado="{ data, value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: data.ColorEstado }"
        >{{ value }}</span
      >
    </template>
  </table-data-lazy>
  <!-- Modales -->
  <producto-modal
    ref="productoModal"
    v-model="abrirProductoModal"
    v-model:datos="producto"
    v-model:atributosProducto="productoAtributo"
    @crear-producto="crearProducto"
    @editar-producto="actualizarProducto"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableDataLazy from "@/components/TableDataLazy.vue";
import ProductoModal from "@/components/maestros/productos/ProductoModal.vue";

export default {
  components: { TableDataLazy, ProductoModal },
  data() {
    return {
      producto: {},
      productoAtributo: [],
      columns: [],
      emptyMsg: "No se encontraron productos...",
      abrirProductoModal: false,
    };
  },
  computed: {
    ...mapGetters({
      lista: "productos/getLista",
      total: "productos/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "Descripcion", header: "Descripción" },
      { field: "IdTEstado", header: "Estado" },
      { field: "Color", header: "Color" },
    ];
  },
  async mounted() {
    await this.listar();
    this.getCbxAtributo();
  },
  methods: {
    ...mapActions({
      listar: "productos/listar",
      crear: "productos/crear",
      actualizar: "productos/actualizar",
      getCbxAtributo: "cbx/getCbxAtributo",
      getProductoAtributo: "productos/listarProductoAtributo",
    }),
    ...mapMutations({
      agregarCreado: "productos/setCreado",
      agregarActualizado: "productos/setActualizado",
    }),
    async crearProducto(datos) {
      const data = await this.crear(datos);
      if (data) {
        this.agregarCreado(data);
        this.$refs.productoModal.resetModal();
        this.$toast.add(this.$alerts.productoCreado);
      }
    },
    async actualizarProducto(datos) {
      const data = await this.actualizar(datos);
      if (data) {
        this.agregarActualizado(data);
        this.$refs.productoModal.resetModal();
        this.$toast.add(this.$alerts.productoActualizado);
      }
    },
    async preEditar(data) {
      this.producto = data;
      const res = await this.getProductoAtributo(this.producto.Id);
      if (res) this.productoAtributo = res;
      this.abrirProductoModal = true;
    },
  },
};
</script>
