<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEditGarantto"
    style="width: 1000px"
    class="gcompanias-modal"
    position="top"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <template #top-bar-buttons>
      <button-options v-if="userCanEditGarantto && esEdicion" :options="menuOptions" />
    </template>
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 my-3" v-if="v$.form.$error" />
    <div class="max-content">
      <tab-view>
        <tab-panel header="Datos básicos">
          <div class="formgrid grid my-5">
            <div class="col-6">
              <p-float-label label="Nombre comercial" required>
                <input-text
                  type="text"
                  v-model="v$.form.NombreComercial.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.NombreComercial.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6">
              <p-float-label label="Email">
                <input-text
                  type="text"
                  v-model="v$.form.Email.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Email.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Teléfono">
                <input-text
                  type="text"
                  v-model="v$.form.Telefono.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Telefono.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-5">
              <whatsapp-input
                v-model="v$.form.WhatsApp.$model"
                :validator="v$.form.WhatsApp.$error"
                :disabled="!userCanEditGarantto"
              />
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Ciudad" required>
                <dropdown-element
                  v-model="v$.form.IdCiudad.$model"
                  :loading="loadingCbx"
                  :options="listaCiudades"
                  filter
                  optionLabel="Ciudad"
                  optionValue="Id"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdCiudad?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Dirección" required>
                <input-text
                  type="text"
                  v-model="v$.form.Direccion.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Direccion.$error,
                  }"
                />
              </p-float-label>
            </div>
            <template v-if="esEdicion">
              <div class="col-6 mt-5">
                <p-float-label label="Latitud">
                  <input-text type="text" :modelValue="form.Latitud" disabled />
                </p-float-label>
              </div>
              <div class="col-6 mt-5">
                <p-float-label label="Longitud">
                  <input-text
                    type="text"
                    :modelValue="form.Longitud"
                    disabled
                  />
                </p-float-label>
              </div>
            </template>
            <div class="field-checkbox col-6 mt-5">
              <TriStateCheckbox
                v-model="v$.form.DomicilioPropio.$model"
                :disabled="!userCanEditGarantto"
              />
              <label>Domicilio propio</label>
            </div>
            <div class="col-6 mt-5">
              <uploader-url
                label="Página web"
                :url="form.PaginaWeb"
                :validation="esLink"
              >
                <input-text
                  type="text"
                  v-model="v$.form.PaginaWeb.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.PaginaWeb?.$error,
                  }"
                />
              </uploader-url>
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Estado" required>
                <dropdown-element
                  v-model="v$.form.IdEstado.$model"
                  :loading="loadingCbx"
                  :options="listaGaranttoEstados"
                  optionLabel="Descripcion"
                  optionValue="Id"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdEstado?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Usuario" required>
                <dropdown-element
                  v-model="v$.form.IdUsuario.$model"
                  :loading="loadingCbx"
                  :options="listaUsuarios"
                  :optionLabel="(data) => `${data.Nombres} ${data.Apellidos}`"
                  optionValue="Id"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdUsuario?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-5">
              <p-float-label label="Id google maps" required>
                <input-text
                  type="text"
                  v-model="v$.form.IdGoogleMaps.$model"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.IdGoogleMaps?.$error,
                  }"
                />
              </p-float-label>
            </div>
            <div v-if="esEdicion" class="col-6 mt-5">
              <p-float-label label="Fecha últ. modificación">
                <input-text
                  :modelValue="formatToLocalTime(form.TS)"
                  type="text"
                  disabled
                />
              </p-float-label>
            </div>
            <div class="col-6 mt-3">
              <small>Licencia</small><br>
              <div v-if="datos?.Licencia" class="flex flex-column gap-2">
                <InputText :value="datos?.Licencia" disabled />
              </div>
              <button-element v-else label="Crear licencia" 
                :disabled="!form?.Email || v$?.form?.Email.$error" 
                @click="crearLicencia(datos?.Id)"/>
            </div>
            <div class="col-12 mt-5">
              <p-float-label label="Comentario">
                <text-area
                  v-model="v$.form.Comentario.$model"
                  rows="2"
                  :disabled="!userCanEditGarantto"
                  :class="{
                    'p-invalid': v$?.form?.Comentario?.$error,
                  }"
                />
              </p-float-label>
            </div>
          </div>
        </tab-panel>
        <tab-panel v-if="esEdicion" header="Marcas">
          <table-crud
            :lista="form.CompaniaMarca"
            :columns="columnsCompaniaMarca"
            :showHeader="false"
            :showEditButton="false"
            :showAddButton="userCanEditGarantto"
            :showDeleteButton="userCanEditGarantto"
            emptyMessage="No hay registros..."
            @crear="
              form.CompaniaMarca.push({
                IdG_Marca: null,
                IdG_MarcaCategoria: null,
                EsAutorizado: false,
              })
            "
            @eliminar="(data, i) => form.CompaniaMarca.splice(i, 1)"
          >
            <template #IdG_Marca="{ data, index }">
              <dropdown-element
                v-model="data['IdG_Marca']"
                filter
                :loading="loadingCbx"
                :options="listaGaranttoMarcas"
                optionValue="Id"
                optionLabel="Descripcion"
                placeholder="Seleccionar marca"
                :disabled="!userCanEditGarantto"
                :class="{
                  'p-invalid':
                    v$?.form?.CompaniaMarca?.$each?.$response?.$data?.[index]
                      ?.IdG_Marca?.$error && v$?.$dirty,
                }"
                @change="data['IdG_MarcaCategoria'] = null"
              />
            </template>
            <template #IdG_MarcaCategoria="{ data, index }">
              <multi-select
                v-model="data['IdG_MarcaCategoria']"
                filter
                :loading="loadingCbx"
                emptyMessage="No se encontraron categorías asociadas"
                :options="
                  listaGaranttoMarcaCategoria?.[data['IdG_Marca']] ?? []
                "
                optionValue="Id"
                optionLabel="Descripcion"
                placeholder="Seleccionar categorías"
                :disabled="!userCanEditGarantto"
                :class="{
                  'p-invalid':
                    v$?.form?.CompaniaMarca?.$each?.$response?.$data?.[index]
                      ?.IdG_MarcaCategoria?.$error && v$?.$dirty,
                }"
              />
            </template>
            <template #EsAutorizado="{ data }">
              <check-box 
                v-model="data['EsAutorizado']" 
                :binary="true" 
                :disabled="!userCanEditGarantto" 
              />
            </template>
          </table-crud>
        </tab-panel>
      </tab-view>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  url,
  email,
  maxLength,
  helpers,
} from "@vuelidate/validators";
import { duplicarCompania } from "../services/gcompanias";
import { whatsappValidator, formatToLocalTime } from "@/helpers";
import WhatsappInput from "@/components/WhatsappInput.vue";
import UploaderUrl from "@/components/UploaderUrl.vue";
import TableCrud from "@/components/TableCrud.vue";
import TriStateCheckbox from 'primevue/tristatecheckbox';
import { crearLicenciaCompania } from '../services/gcompanias';

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    WhatsappInput,
    UploaderUrl,
    TableCrud,
    TriStateCheckbox,
  },
  data() {
    return {
      form: { IdEstado: 3, IdGoogleMaps:null, CompaniaMarca: [], DomicilioPropio: false },
      columnsCompaniaMarca: [
        { field: "IdG_Marca", header: "Marca" },
        { field: "IdG_MarcaCategoria", header: "Categoría" },
        { field: "EsAutorizado", header: "Autorizado" },
      ],
      menuOptions: [
        {
          label: "Duplicar",
          icon: "pi pi-clone",
          command: () => {
            this.duplicarHandler();
          },
        },
      ],
      loadingCbx: true,
    };
  },
  computed: {
    ...mapState(["auth"]),
    ...mapGetters({
      listaCiudades: "cbx/getGaranttoCiudadesMaestro",
      listaUsuarios: "cbx/getUsuarios",
      listaGaranttoMarcas: "cbx/getGaranttoMarca",
      listaGaranttoEstados: "cbx/getGaranttoEstado",
      listaGaranttoMarcaCategoria: "cbx/getGaranttoMarcaCategoria",
      userCanEditGarantto: "auth/userCanEditGarantto",
    }),
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    title() {
      if (this.esEdicion) return "Editar compañía";
      return "Crear compañía";
    },
    esLink() {
      if (!this.form.PaginaWeb) return false;
      if (this.v$?.form?.PaginaWeb?.$error) return false;
      return true;
    },
  },
  async mounted() {
    this.loadingCbx = true;
    await this.getCbxUsuarios();
    await this.getCbxGaranttoMarca();
    await this.getCbxGaranttoEstado();
    await this.getCbxGaranttoMarcaCategoria();
    this.loadingCbx = false;
    // Empezar con el usuario de la sesión
    this.form.IdUsuario = this.auth?.user?.Id;
  },
  methods: {
    formatToLocalTime,
    ...mapMutations("gcompanias", ["setCreated"]),
    ...mapActions({
      crear: "gcompanias/crear",
      actualizar: "gcompanias/actualizar",
      getCbxUsuarios: "cbx/getCbxUsuarios",
      getCbxGaranttoMarca: "cbx/getCbxGaranttoMarca",
      getCbxGaranttoEstado: "cbx/getCbxGaranttoEstado",
      getCbxGaranttoMarcaCategoria: "cbx/getCbxGaranttoMarcaCategoria",
    }),
    resetModal() {
      this.form = { IdEstado: 3, IdUsuario: this.auth?.user?.Id, CompaniaMarca: [], DomicilioPropio: false };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async cerrarModal() {
      const formHasChanged = this.v$.form.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;
      if (this.esEdicion) this.editarHandler();
      else this.crearHandler();
    },
    async crearHandler() {
      const { data, status } = await this.crear(this.form);
      if (status == 201) {
        this.resetModal();
        this.$toast.add(this.$alerts.creado);
        this.$emit("crear", data);
      }
    },
    async editarHandler() {
      const status = await this.actualizar(this.form);
      if (status == 200) {
        this.resetModal();
        this.$toast.add(this.$alerts.actualizado);
      }
    },
    async duplicarHandler() {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await duplicarCompania(this.form.Id);
      this.$store.commit("setIsLoading", false);
      if (status == 201) {
        this.$toast.add(this.$alerts.duplicado);
        this.setCreated(data);
        this.form = data;
      } else this.$alertStatus(status, data);
    },
    async crearLicencia(idCompania) {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;

      this.$store.commit("setIsLoading", true)
      const response = await this.actualizar(this.form)
      this.$store.commit("setIsLoading", false)

      if (response === 200) {
        this.$store.commit("setIsLoading", true)
        const { status, data } = await crearLicenciaCompania(idCompania);
        this.$store.commit("setIsLoading", false)

        if (status === 201 || status === 207) {
          this.$store.commit('gcompanias/setAddLicense', {
            idCompania,
            licencia: data.resultado.IdTLicencia
          })
          this.resetModal();
          if (status === 201) this.$toast.add(this.$alerts.creado)
        }

        this.$alertStatus(status, data)

      } else {
        this.$alertStatus(response);
      }
    }
  },
  validations() {
    return {
      form: {
        IdCiudad: { required },
        IdEstado: { required },
        IdUsuario: { required },
        NombreComercial: { required, maxLength: maxLength(255) },
        Telefono: { maxLength: maxLength(255) },
        WhatsApp: { whatsappValidator, maxLength: maxLength(255) },
        Direccion: { required, maxLength: maxLength(255) },
        Email: { email, maxLength: maxLength(255) },
        PaginaWeb: { url, maxLength: maxLength(255) },
        DomicilioPropio: {},
        Comentario: { maxLength: maxLength(1024) },
        IdGoogleMaps: { required, maxLength: maxLength(256) },
        CompaniaMarca: {
          $each: helpers.forEach({
            IdG_Marca: {
              required,
            },
            IdG_MarcaCategoria: {
              required,
            },
          }),
        },
      },
    };
  },
  watch: {
    datos(val) {
      if (val === null) return;
      this.form = { ...val };
    },
  },
};
</script>

<style lang="scss">
.gcompanias-modal {
  table .p-multiselect {
    & .p-multiselect-label {
      white-space: break-spaces;
    }
    &.p-inputwrapper {
      height: auto;
      min-width: 600px;
    }
  }
  &.modal-container table {
    .p-dropdown {
      min-width: 200px;
    }
  }
}
</style>
