<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEditGarantto"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.form.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <p-float-label label="Código" required>
          <input-text
            type="text"
            v-model="v$.form.IdT.$model"
            :disabled="!userCanEditGarantto"
            :class="{
              'p-invalid': v$?.form?.IdT?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Alias">
          <chips-component
            v-model="v$.form.Alias.$model"
            :invalid="v$?.form?.Alias?.$error"
            :disabled="!userCanEditGarantto"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Estado" required>
          <dropdown-element
            v-model="v$.form.IdEstado.$model"
            :loading="isLoading"
            :options="listaEstados"
            optionLabel="Descripcion"
            optionValue="Id"
            :disabled="!userCanEditGarantto"
            :class="{
              'p-invalid': v$?.form?.IdEstado?.$error,
            }"
          />
        </p-float-label>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import ChipsComponent from "@/components/ChipsComponent.vue";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ChipsComponent,
  },
  data() {
    return {
      form: { IdEstado: 1 },
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      userCanEditGarantto: "auth/userCanEditGarantto",
    }),
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    title() {
      if (this.esEdicion) return "Editar producto";
      return "Crear producto";
    },
  },
  methods: {
    ...mapActions({
      crear: "gproductos/crear",
      actualizar: "gproductos/actualizar",
    }),
    resetModal() {
      this.form = { IdEstado: 1 };
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:modelValue", false);
    },
    async cerrarModal() {
      const formHasChanged = this.v$.form.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;
      if (this.esEdicion) this.editarHandler();
      else this.crearHandler();
    },
    async crearHandler() {
      const status = await this.crear(this.form);
      if (status == 201) {
        this.resetModal();
        this.$toast.add(this.$alerts.creado);
      }
    },
    async editarHandler() {
      const status = await this.actualizar(this.form);
      if (status == 200) {
        this.resetModal();
        this.$toast.add(this.$alerts.actualizado);
      }
    },
  },
  validations() {
    return {
      form: {
        IdEstado: { required },
        IdT: { required, maxLength: maxLength(255) },
        Alias: { maxLength: maxLength(255) },
      },
    };
  },
  watch: {
    datos(val) {
      if (val === null) return;
      this.form = { ...val };
    },
  },
};
</script>
