import PrimeVue from "primevue/config";
import locale from "./primevueLocale";
import ToastService from "primevue/toastservice";

//Componentes
import Menu from "primevue/menu";
import Card from "primevue/card";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Message from "primevue/message";
import InputNumber from "primevue/inputnumber";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import Editor from "primevue/editor";

const install = (app) => {
  app.use(PrimeVue, { locale });
  app.use(ToastService);

  //Registro de componentes
  app.component("MenuElement", Menu);
  app.component("CardElement", Card);
  app.component("ToastElement", Toast);
  app.component("ButtonElement", Button);
  app.component("DialogElement", Dialog);
  app.component("ToolbarElement", Toolbar);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("TextArea", Textarea);
  app.component("DropdownElement", Dropdown);
  app.component("CheckBox", Checkbox);
  app.component("DataTable", DataTable);
  app.component("ColumnElement", Column);
  app.component("FileUpload", FileUpload);
  app.component("InputText", InputText);
  app.component("MessageElement", Message);
  app.component("CalendarElement", Calendar);
  app.component("InputNumber", InputNumber);
  app.component("InputChips", Chips);
  app.component("MultiSelect", MultiSelect);
  app.component("EditorElement", Editor);
};
export default install;
