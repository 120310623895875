<template>
  <dialog-element
    :modal="true"
    :visible="modelValue"
    :closable="false"
    :draggable="false"
    class="modal-container"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center w-full">
        <div class="flex align-items-center">
          <button-element
            v-if="esMovil"
            icon="pi pi-arrow-left"
            class="mr-2"
            @click="cerrarCreacion"
          />
          <span>Crear tipo de contrato</span>
        </div>
        <div>
          <button-element
            icon="pi pi-save"
            class="mr-2"
            @click="guardarCreacion"
          />
          <button-element
            v-if="!esMovil"
            icon="pi pi-times"
            @click="cerrarCreacion"
          />
        </div>
      </div>
    </template>
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.$invalid && validar" />
    <div class="formgrid grid my-5">
      <span class="p-float-label col-12">
        <input-text
          id="codigo"
          type="text"
          v-model="v$.tcontratoNuevo.IdT.$model"
          :class="{
            'p-invalid': v$?.tcontratoNuevo?.IdT?.$invalid && validar,
          }"
        />
        <label for="codigo" class="input-required"> Código </label>
      </span>
      <span class="p-float-label col-12 mt-5">
        <input-text
          id="descripcion"
          type="text"
          v-model="v$.tcontratoNuevo.Descripcion.$model"
          :class="{
            'p-invalid': v$?.tcontratoNuevo?.Descripcion?.$invalid && validar,
          }"
        />
        <label for="descripcion" class="input-required"> Descripción </label>
      </span>
      <span class="p-float-label col-12 mt-5">
        <input-number
          id="frecuencia-mes"
          v-model="v$.tcontratoNuevo.FrecuenciaMes.$model"
          :useGrouping="false"
          :class="{
            'p-invalid': v$?.tcontratoNuevo?.FrecuenciaMes?.$invalid && validar,
          }"
        />
        <label for="descripcion"> Frecuencia mes </label>
      </span>
    </div>
  </dialog-element>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      validar: false,
      tcontratoNuevo: {
        IdT: null,
        Descripcion: null,
      },
    };
  },
  computed: {
    ...mapState(["esMovil"]),
  },
  methods: {
    async cerrarCreacion() {
      if (!this.v$.$anyDirty) {
        this.cerrarModal();
        return;
      }
      const res = await this.$alerts.descartarCambios();
      if (res.isConfirmed) {
        this.cerrarModal();
      }
    },
    guardarCreacion() {
      this.validar = true;
      if (this.v$.$invalid) return;
      this.$emit("crear", this.tcontratoNuevo);
    },
    cerrarModal() {
      this.tcontratoNuevo = {
        IdT: null,
        Descripcion: null,
      };
      this.v$.$reset();
      this.validar = false;
      this.$emit("update:modelValue", false);
    },
  },
  validations() {
    return {
      tcontratoNuevo: {
        IdT: { required },
        Descripcion: { required },
        FrecuenciaMes: {},
      },
    };
  },
};
</script>
