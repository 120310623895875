<template>
  <modal-wrapper
    :title="title"
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.producto.$error" />
    <div class="formgrid grid my-5">
      <div class="col-12">
        <p-float-label label="Estado" required>
          <dropdown-element
            v-model="v$.producto.IdEstado.$model"
            :loading="isLoading"
            :options="listaEstados"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.producto?.IdEstado?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Código" required>
          <input-text
            type="text"
            v-model="v$.producto.IdT.$model"
            :class="{
              'p-invalid': v$?.producto?.IdT?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-12 mt-5">
        <p-float-label label="Descripción" required>
          <input-text
            type="text"
            v-model="v$.producto.Descripcion.$model"
            :class="{
              'p-invalid': v$?.producto?.Descripcion?.$error,
            }"
          />
        </p-float-label>
      </div>
      <div class="col-6 mt-5">
        <p-float-label label="Color" required>
          <input-text type="text" v-model="v$.producto.Color.$model" disabled />
        </p-float-label>
      </div>
      <div class="mt-5 col-6">
        <input
          type="color"
          v-model="v$.producto.Color.$model"
          style="height: 37px; width: 40px; border-radius: 5px"
        />
      </div>
      <div v-if="esEdicion" class="col-12 mt-5">
        <button-element
          @click="addAtributo"
          label="Añadir atributo"
          icon="pi pi-plus"
          class="p-button-secondary w-full"
        />
      </div>
      <div v-if="hayAtributos && esEdicion" class="col-12 mt-3">
        <div
          v-for="(atributo, index) in atributos"
          :key="index"
          class="p-inputgroup w-full mt-2"
        >
          <button-element
            @click="eliminarAtributo(atributo, index)"
            icon="pi pi-times"
            class="p-button-danger"
          />
          <dropdown-element
            @change="actualizarAtributo(atributo, index)"
            v-model="atributos[index].IdAtributo"
            :loading="isLoading"
            :options="listaAtributos"
            placeholder="Selecciona un atributo"
            optionLabel="CodigoDescripcion"
            optionValue="Id"
          />
        </div>
      </div>
    </div>
    <atributo-sub-modal
      v-model="abrirSubModal"
      :productoId="producto.Id"
      @mostrarAtributo="mostrarAtributo"
    />
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AtributoSubModal from "@/components/maestros/productos/AtributoSubModal.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: { AtributoSubModal },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    modelValue: Boolean,
    datos: Object,
    atributosProducto: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      producto: {
        IdEstado: 1,
        Color: "#c8e6c9",
      },
      atributos: [],
      abrirSubModal: false,
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaAtributos: "cbx/getAtributo",
      userCanEdit: "auth/userCanEdit",
    }),
    title() {
      if (this.esEdicion) return "Editar producto";
      return "Crear producto";
    },
    esEdicion() {
      return Object.entries(this.datos || {}).length > 0;
    },
    hayAtributos() {
      if (this.atributos.length === 0) return false;
      return true;
    },
  },
  methods: {
    ...mapActions({
      crearProductoAtributo: "productos/crearProductoAtributo",
      actualizarProductoAtributo: "productos/actualizarProductoAtributo",
      eliminarProductoAtributo: "productos/eliminarProductoAtributo",
    }),
    async cerrarModal() {
      const formHasChanged = this.v$.producto.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.producto.$validate();
      if (!isFormCorrect) return;
      if (!this.esEdicion) this.$emit("crearProducto", this.producto);
      else this.$emit("editarProducto", this.producto);
    },
    resetModal() {
      this.producto = {
        IdEstado: 1,
        Color: "#c8e6c9",
      };
      this.atributos = [];
      this.v$.$reset();
      this.$emit("update:datos", null);
      this.$emit("update:atributosProducto", null);
      this.$emit("update:modelValue", false);
    },
    addAtributo() {
      this.abrirSubModal = true;
    },
    async actualizarAtributo({ Id, IdAtributo }, index) {
      const payload = {
        productoId: this.producto.Id,
        atributoId: Id,
        atributoNuevo: { IdAtributo },
      };
      const data = await this.actualizarProductoAtributo(payload);
      if (data) {
        this.atributos[index] = data;
        this.$toast.add(this.$alerts.atributoActualizado);
      }
    },
    async eliminarAtributo(atributo, index) {
      const payload = {
        productoId: this.producto.Id,
        atributoId: atributo.Id,
      };
      const res = await this.eliminarProductoAtributo(payload);
      if (res) {
        this.atributos.splice(index, 1);
        this.$toast.add(this.$alerts.atributoEliminado);
      }
    },
    mostrarAtributo(data) {
      this.atributos.push(data);
    },
  },
  validations() {
    return {
      producto: {
        IdEstado: { required },
        IdT: { required },
        Descripcion: { required },
        Color: { required },
      },
    };
  },
  watch: {
    datos: {
      handler() {
        if (this.datos === null) return;
        this.producto = { ...this.datos };
      },
    },
    atributosProducto(valor) {
      if (valor) {
        this.atributos = valor.map((elemento) => ({ ...elemento }));
      }
    },
  },
};
</script>
