<template>
  <toolbar-element class="submenu-tabview">
    <template #start>
      <h3 class="m-0">Lista de sedes</h3>
    </template>
    <template #end>
      <button-element
        label="Nueva sede"
        icon="pi pi-plus"
        class="p-button-success"
        :disabled="!userCanEdit"
        @click="$emit('abrirCrear')"
      />
    </template>
  </toolbar-element>
  <table-component
    :options="sedes"
    :columns="columns"
    :filters="filters"
    :empty="emptyMsg"
    :disableEditButton="!userCanEdit"
    @abrir-editar="abrirEditar"
  >
    <template #EsPrincipal="{ data }">
      <i
        :class="data.EsPrincipal ? 'pi pi-check' : 'pi pi-times'"
        :style="data.EsPrincipal ? 'color: green' : 'color: red'"
      ></i>
    </template>
    <template #IdTEstado="{ data, value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: data.ColorEstado }"
        >{{ value }}</span
      >
    </template>
  </table-component>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";

export default {
  components: { TableComponent },
  props: {
    sedes: {
      type: Array,
    },
  },
  emits: ["abrirEditar", "abrirCrear"],
  data() {
    return {
      columns: [
        { field: "RazonSocial", header: "Razón social" },
        { field: "Celular", header: "Celular" },
        { field: "EsPrincipal", header: "Principal" },
        { field: "IdTEstado", header: "Estado" },
        { field: "Direccion", header: "Dirección" },
        { field: "IdTCiudad", header: "Ciudad" },
        { field: "IdTPais", header: "País" },
      ],
      filters: {
        RazonSocial: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Celular: { value: null, matchMode: FilterMatchMode.CONTAINS },
        EsPrincipal: { value: null, matchMode: FilterMatchMode.CONTAINS },
        IdTEstado: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Direccion: { value: null, matchMode: FilterMatchMode.CONTAINS },
        IdTCiudad: { value: null, matchMode: FilterMatchMode.CONTAINS },
        IdTPais: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      emptyMsg: "No se han encontrado sedes...",
    };
  },
  computed: {
    ...mapGetters({
      userCanEdit: "auth/userCanEdit",
    }),
  },
  methods: {
    abrirEditar(data) {
      this.$emit("abrirEditar", data);
    },
  },
};
</script>
