<template>
  <modal-wrapper
    :isVisible="modelValue"
    :userCanEdit="userCanEdit"
    @cerrar-modal="cerrarModal"
    @guardar-modal="guardarModal"
  >
    <!-- Formulario -->
    <mensaje-invalidos class="m-0 mt-3" v-if="v$.form.$error" />
    <div class="formgrid grid my-3">
      <div class="col-12">
        <p-form-field
          required
          label="Contacto"
          showCreateButton
          :showEditButton="form?.IdContacto != null"
          @click-create="abrirModal = true"
          @click-edit="preEditar"
        >
          <combo-box-lazy
            v-model="v$.form.IdContacto.$model"
            :options="lista"
            optionLabel="Contacto"
            optionValue="Id"
            placeholder="Buscar por nombre o correo electrónico"
            :loading="loading"
            :class="{
              'p-invalid': v$?.form?.IdContacto?.$error,
            }"
            style="width: 20px; flex: 1"
            @search="buscarContactos"
            @select="seleccionarContacto"
            @clear="clearForm"
          />
        </p-form-field>
      </div>
      <div class="col-12 mt-3">
        <p-form-field label="Cargo" required>
          <dropdown-element
            v-model="v$.form.IdCargo.$model"
            :loading="isLoading"
            :options="cargos"
            optionLabel="Descripcion"
            optionValue="Id"
            :class="{
              'p-invalid': v$?.form?.IdCargo?.$error,
            }"
          />
        </p-form-field>
      </div>
      <div class="field-checkbox col-12 mt-3">
        <label for="principal" class="font-bold text-sm mx-2">
          Principal
        </label>
        <check-box
          v-model="v$.form.Principal.$model"
          id="principal"
          :binary="true"
        />
      </div>
    </div>
  </modal-wrapper>
  <contacto-modal
    v-model="abrirModal"
    v-model:datos="editable"
    @crear="asignarContacto"
    @editar="asignarContacto"
  />
</template>

<script>
import ContactoModal from "@/components/contacto/ContactoModal.vue";
import ComboBoxLazy from "@/components/ComboBoxLazy.vue";
import PFormField from "@/components/PFormField.vue";
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { listarCbxContactos, buscarContacto } from "@/services/contactos";
import {
  crearClienteContacto,
  editarClienteContacto,
} from "@/services/clienteContacto";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    contacto: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  components: { ContactoModal, ComboBoxLazy, PFormField },
  data() {
    return {
      form: {
        Principal: false,
        IdCargo: 1,
      },
      lista: [],
      loading: false,
      editable: null,
      abrirModal: false,
    };
  },
  computed: {
    ...mapState("cbx", ["isLoading"]),
    ...mapGetters({
      cargos: "cbx/getCargo",
      userCanEdit: "auth/userCanEdit",
    }),
    esEdicion() {
      return Object.entries(this.contacto || {}).length > 0;
    },
    clienteId() {
      return this.$route.params.id;
    },
    clienteContactoId() {
      return this.contacto?.IdClienteContacto;
    },
  },
  methods: {
    async cerrarModal() {
      const formHasChanged = this.v$.form.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.resetModal();
        return;
      }
      this.resetModal();
    },
    async guardarModal() {
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) return;
      this.$store.commit("setIsLoading", true);
      if (this.esEdicion) await this.editarClienteContacto();
      else await this.crearClienteContacto();
      this.$store.commit("setIsLoading", false);
    },
    resetModal() {
      this.clearForm();
      this.$emit("update:contacto", null);
      this.$emit("update:modelValue", false);
    },
    clearForm() {
      this.form = {
        Principal: false,
        IdCargo: 1,
      };
      this.v$.$reset();
      this.lista = [];
      this.loading = false;
      this.editable = null;
    },
    async crearClienteContacto() {
      const { data, status } = await crearClienteContacto(
        this.form,
        this.clienteId
      );
      if (status == 201) {
        this.resetModal();
        this.$toast.add(this.$alerts.creado);
        this.$store.commit("clientes/setContactosAdd", data);
      } else this.$alertStatus(status, data);
    },
    async editarClienteContacto() {
      const { data, status } = await editarClienteContacto(
        this.form,
        this.clienteId,
        this.clienteContactoId
      );
      if (status == 200) {
        this.resetModal();
        this.$toast.add(this.$alerts.actualizado);
        this.$store.commit("clientes/setContactosEdit", data);
      } else this.$alertStatus(status, data);
    },
    async buscarContactos(value, rows = 20) {
      const params = {
        filters: { Contacto: value },
        rows,
      };
      this.clearForm();
      this.loading = true;
      const { data, status } = await listarCbxContactos(params);
      this.loading = false;
      if (status == 200) this.lista = data;
      else this.$alertStatus(status, data);
    },
    seleccionarContacto({ value }) {
      const item = this.lista.find((el) => el.Id == value);
      if (item) {
        this.form.IdContacto = item.Id;
        this.v$.form.IdContacto.$touch();
      }
    },
    async preEditar() {
      this.$store.commit("setIsLoading", true);
      const { data, status } = await buscarContacto(this.form.IdContacto);
      this.$store.commit("setIsLoading", false);
      if (status == 200) {
        this.editable = data;
        this.abrirModal = true;
      } else this.$alertStatus(status, data);
    },
    asignarContacto(c) {
      const str = `${c.Identificacion || ""}-${c.Nombre1}
                          ${c.Nombre2 || ""} ${c.Apellido1} ${c.Apellido2 || ""}
                          ${c.Email || ""}`;

      const contacto = {
        Id: c.Id,
        Contacto: str.replace(/\s+/g, " ").trim(),
      };

      if (this.form?.IdContacto == c.Id) {
        this.lista.forEach((el, i) => {
          if (el.Id == this.form.IdContacto) {
            this.lista[i] = contacto;
            this.v$.form.IdContacto.$touch();
          }
        });
      } else {
        this.form.IdContacto = c.Id;
        this.form.IdCargo = c.IdCargo || 1;
        this.form.Principal = c.Principal || false;
        this.lista.push(contacto);
      }
    },
  },
  validations() {
    return {
      form: {
        IdContacto: { required },
        IdCargo: { required },
        Principal: {},
      },
    };
  },
  watch: {
    contacto(value) {
      if (value) this.asignarContacto(value);
    },
  },
};
</script>
