<template>
  <div class="max-content">
    <cotizador-form v-if="!viewResult" @mostrarResultado="mostrarResultado" :cotizacion="datosCotizacion" />
    <cotizador-result v-else :datosCotizacion="datosCotizacion" @volver="volverCotizar" />
  </div>
</template>

<script>
import CotizadorForm from "@/components/maestros/cotizador/CotizadorForm.vue";
import CotizadorResult from "@/components/maestros/cotizador/CotizadorResult.vue";

export default {
  components: {
    CotizadorForm,
    CotizadorResult,
  },
  data() {
    return {
      viewResult: false,
      datosCotizacion: null,
    };
  },
  methods: {
    mostrarResultado(datos) {
      this.datosCotizacion = datos;
      this.viewResult = true;
    },
    volverCotizar(value) {
      if (value) this.datosCotizacion = null;
      this.viewResult = false;
    },
  },
};
</script>
