import axios from "./config";

export const buscarCertificado = async (contratoId) => {
  const { data, status } = await axios
    .get(`/contrato/${contratoId}/certificado`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearCertificado = async (contratoId, certificado) => {
  const { data, status } = await axios
    .post(`/contrato/${contratoId}/certificado`, certificado)
    .catch((e) => e.response);
  return { data, status };
};

export const editarCertificado = async (contratoId, certificado) => {
  const { data, status } = await axios
    .post(`/contrato/${contratoId}/certificado/0`, certificado)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarCertificado = async (contratoId) => {
  const { data, status } = await axios
    .delete(`/contrato/${contratoId}/certificado/0`)
    .catch((e) => e.response);
  return { data, status };
};

export const descargarCertificado = async (contratoId) => {
  const { data, status, headers } = await axios
    .get(`/contrato/${contratoId}/certificado`, {
      params: { download: 1 },
      responseType: "blob",
    })
    .catch((e) => e.response);
  return { data, status, headers };
};

