<template>
  <dropdown-element
    :modelValue="modelValue"
    :options="options"
    :optionLabel="optionLabel"
    :optionValue="optionValue"
    :loading="loading"
    showClear
    @change="onChange"
  >
    <template #header>
      <form @submit.prevent="onSearch" class="flex p-2 bg-gray-200 w-full">
        <input-text v-model="searchValue" :placeholder="placeholder" />
        <button-element icon="pi pi-search" type="submit" />
      </form>
    </template>
    <template #empty>
      <span v-if="!loading">{{ emptyMessage }}</span>
      <div v-else class="flex align-items-center p-2 w-full h-full bg-white">
        <progress-spinner strokeWidth="6" style="height: 30px; width: 30px" />
      </div>
    </template>
  </dropdown-element>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
export default {
  props: {
    modelValue: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionLabel: {
      type: [String, Function],
    },
    optionValue: {
      type: [String, Function],
    },
    placeholder: {
      type: String,
      default: "Buscar registros",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: "No se han encontrado coincidencias",
    },
  },
  emits: ["clear", "select", "search"],
  components: { ProgressSpinner },
  data() {
    return {
      searchValue: null,
      oldValue: null,
    };
  },
  methods: {
    onChange(e) {
      if (e?.value && e.value != null) {
        this.$emit("select", e);
        return;
      }
      this.$emit("clear");
      this.searchValue = null;
      this.oldValue = null;
    },
    onSearch() {
      if (!this.searchValue) return;
      if (this.oldValue == this.searchValue) return;
      this.oldValue = this.searchValue;
      this.$emit("search", this.searchValue);
    },
  },
};
</script>
