import axios from "./config";

export const actualizarLogo = async (logo, clienteId) => {
  const { data, status } = await axios
    .post(`cliente/${clienteId}/logo`, logo)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarLogo = async (clienteId) => {
  const { data, status } = await axios
    .delete(`cliente/${clienteId}/logo`)
    .catch((e) => e.response);
  return { data, status };
};
