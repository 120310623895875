import {
  buscarContrato,
  crearContrato,
  duplicarContrato,
  actualizarContrato,
} from "@/services/contratos";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async buscar({ commit }, contratoId) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await buscarContrato(contratoId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async crear({ commit }, { datos, clienteId }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearContrato(datos, clienteId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
  async actualizar({ commit }, { datos, clienteId }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarContrato(datos, clienteId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 200 ? data : this.$alertStatus(status, data);
  },
  async duplicar({ commit }, contratoId) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await duplicarContrato(contratoId);
    commit("setIsLoading", false, {
      root: true,
    });
    return status == 201 ? data : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
