<template>
  <div class="p-inputgroup">
    <span class="p-float-label">
      <slot />
      <label :class="{ 'input-required': required && !isValid }"
        >{{ label }}
        <i
          v-if="isValid"
          class="pi pi-check-circle"
          style="font-size: 10px; color: green"
        ></i
      ></label>
    </span>
    <button-element
      icon="pi pi-eye"
      class="p-button-success"
      @click="clickAnchorTag"
      :disabled="!isValid"
    />
    <a :href="`${url}`" ref="anchorTag" target="_blank" class="hidden" />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    url: String,
    validation: Boolean,
    required: Boolean,
  },
  computed: {
    isValid() {
      return this.validation;
    },
  },
  methods: {
    clickAnchorTag() {
      this.$refs.anchorTag.click();
    },
  },
};
</script>
