<template>
    <modal-wrapper :isVisible="modelValue" :showSaveButton="false" @cerrar-modal="cerrarModal">
        <mensaje-invalidos class="m-0 mt-3" v-if="false" />
        <table class="w-full my-3">
            <tr>
                <td>
                    <label for="certificado" class="input-required">
                        Certificado
                    </label>
                </td>
                <td>
                    <file-uploader :value="form.Certificado" :disabled="disabled" :auto="false"
                        :invalid="v$?.form?.Certificado?.$error" emitLinkEvent accept=".pfx, .txt"
                        @change-file="changeFileHandler" @clean-file="form.Certificado = null"
                        @click-link="descargarHandler" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-codigo" class="input-required">
                        Código
                    </label>
                </td>
                <td>
                    <input-text v-model="form.IdT" id="certificado-codigo" :disabled="disabled" :class="{
                        'p-invalid': v$?.form?.IdT?.$error,
                    }" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-clave" class="input-required">
                        Clave
                    </label>
                </td>
                <td>
                    <input-text v-model="form.ClaveCertificado" id="certificado-clave" :disabled="disabled" :class="{
                        'p-invalid': v$?.form?.ClaveCertificado?.$error,
                    }" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-serial" class="input-required">
                        Serial
                    </label>
                </td>
                <td>
                    <input-text v-model="form.SerialCertificado" id="certificado-serial" :disabled="disabled" :class="{
                        'p-invalid': v$?.form?.SerialCertificado?.$error,
                    }" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-software" class="input-required">
                        Software ID
                    </label>
                </td>
                <td>
                    <input-text v-model="form.SoftwareID" id="certificado-software" :disabled="disabled" :class="{
                        'p-invalid': v$?.form?.SoftwareID?.$error,
                    }" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-test" class="input-required">
                        Test set ID
                    </label>
                </td>
                <td>
                    <input-text v-model="form.TestSetId" id="certificado-test" :disabled="disabled" :class="{
                        'p-invalid': v$?.form?.TestSetId?.$error,
                    }" />
                </td>
            </tr>
            <tr>
                <td>
                    <label for="certificado-fecha" class="input-required">
                        Fecha fin
                    </label>
                </td>
                <td>
                    <calendar-element v-model="form.FechaFinCertificado" id="certificado-fecha" :manualInput="false"
                        dateFormat="yy-mm-dd" showButtonBar showIcon :disabled="disabled" :class="{
                            'p-invalid': v$?.form?.FechaFinCertificado?.$error,
                        }" @update:modelValue="form.FechaFinCertificado = dateParser(form?.FechaFinCertificado)" />
                </td>
            </tr>
        </table>
        <div>
            <button-element label="Guardar" class="p-button-success" :disabled="disabled" @click="guardarModal" />
            <button-element v-if="esEdicion" label="Eliminar" class="ml-1 p-button-danger" :disabled="disabled"
                @click="eliminarHandler" />
        </div>
    </modal-wrapper>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
    crearCertificado,
    editarCertificado,
    descargarCertificado,
    eliminarCertificado
} from "@/services/certificados.js";
import { dateParser } from "@/helpers/dateParser";

export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        datos: {
            type: Object,
            default: () => ({}),
        },
        idContrato: {
            type: [String, Number]
        },
    },
    components: {
        FileUploader
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            form: {}
        }
    },
    computed: {
        esEdicion() {
            return Object.entries(this.datos || {}).length > 0;
        }
    },
    methods: {
        cerrarModal() {
            this.v$.$reset();
            this.$emit("update:datos", null);
            this.$emit("update:modelValue", false);
            this.form = {};
        },
        async guardarModal() {
            const isFormCorrect = await this.v$.form.$validate();
            if (!isFormCorrect) return;
            const formData = this.prepareData();
            if (this.esEdicion) this.editarHandler(formData);
            else this.crearHandler(formData);
        },
        async crearHandler(formData) {
            this.$store.commit("setIsLoading", true);
            const { data, status } = await crearCertificado(this.idContrato, formData);
            this.$store.commit("setIsLoading", false);
            if (status == 201) {
                this.cerrarModal();
                this.$toast.add(this.$alerts.creado);
            } else this.$alertStatus(status, data);
        },
        async editarHandler(formData) {
            this.$store.commit("setIsLoading", true);
            const { data, status } = await editarCertificado(this.idContrato, formData);
            this.$store.commit("setIsLoading", false);
            if (status == 201) {
                this.cerrarModal();
                this.$toast.add(this.$alerts.actualizado);
            } else this.$alertStatus(status, data);
        },
        async eliminarHandler() {
            this.$store.commit("setIsLoading", true);
            const { data, status } = await eliminarCertificado(this.idContrato);
            this.$store.commit("setIsLoading", false);
            if (status == 204) {
                this.cerrarModal();
                this.$toast.add(this.$alerts.eliminado);
            } else this.$alertStatus(status, data);
        },
        async descargarHandler() {
            this.$store.commit("setIsLoading", true);
            const { data, status, headers } = await descargarCertificado(this.idContrato);
            this.$store.commit("setIsLoading", false);
            if (status == 200) {
                const fileName = headers["X-File-Name"] ?? "certificado.pfx"
                //Descargar archivo
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                // Alerta archivo generado
                this.$toast.add(this.$alerts.archivoGenerado);
            } else this.$alertStatus(status, data);
        },
        changeFileHandler({ target }) {
            const [file] = target.files;
            this.form.Certificado = file;
        },
        prepareData() {
            const data = new FormData();
            for (let key in this.form) data.append(key, this.form[key]);
            if (this.esEdicion) data.append("_method", "put");
            return data;
        },
        dateParser,
    },
    validations() {
        return {
            form: {
                IdT: { required },
                Certificado: { required },
                ClaveCertificado: { required },
                SerialCertificado: { required },
                SoftwareID: { required },
                TestSetId: { required },
                FechaFinCertificado: { required },
            },
        };
    },
    watch: {
        datos(val) {
            if (val === null) return;
            this.form = { ...val };
        },
    },
}
</script>