<template>
  <section id="wrapper">
    <toolbar-element class="submenu submenu-cotizador">
      <template #start>
        <img :src="logo" alt="Logo" style="width: 25%" />
      </template>
      <template #end>
        <strong v-show="date" style="color: white">{{ date }}</strong>
      </template>
    </toolbar-element>
    <slot />
    <footer>
      <div class="row flex footer-cotizador">
        <div class="col-6 text-center">
          <h3>
            Un software ERP nunca fue <br />
            tan fácil de usar
          </h3>
        </div>
        <div class="col-6 text-center mt-4">
          <img :src="logo" alt="Logo" style="width: 28%" />
        </div>
      </div>
      <div class="row flex" style="background-color: #fff">
        <div class="col-6 text-right text-apping py-4">
          <p>Un producto de:</p>
        </div>
        <div class="col-6 py-4">
          <img :src="logoAppinglab" alt="AppingLab" style="width: 28%" />
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import logo from "@/assets/Logo_erp_positivo.png";
import logoAppinglab from "@/assets/Logo-appinglab.png";
import moment from 'moment'

export default {
  setup() {
    const date = moment().format("DD/MMM/YY")
    return {
      logo,
      logoAppinglab,
      date,
    }
  }
};
</script>
