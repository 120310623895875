import axios from "./config";

export const crearCiudad = async (idPais, ciudad) => {
  const { data, status } = await axios
    .post(`/paises/${idPais}/ciudades`, ciudad)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarCiudad = async (idPais, ciudad) => {
  const { data, status } = await axios
    .put(`/paises/${idPais}/ciudades/${ciudad.Id}`, ciudad)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarCiudad = async (idPais, idCiudad) => {
  const { data, status } = await axios
    .delete(`/paises/${idPais}/ciudades/${idCiudad}`)
    .catch((e) => e.response);
  return { data, status };
};
