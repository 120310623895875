<template>
  <!-- Submenu -->
  <toolbar-element class="submenu">
    <template #start>
      <button-element
        icon="pi pi-arrow-left"
        @click="navegar('/clientes')"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Formulario -->
  <div class="max-content">
    <h4>INFORMACIÓN BÁSICA</h4>
    <cliente-form ref="clienteForm" @form-submit="crearCliente" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ClienteForm from "@/components/cliente/ClienteForm.vue";

export default {
  components: { ClienteForm },
  methods: {
    ...mapActions({
      crear: "clientes/crear",
    }),
    async navegar(path) {
      const formHasChanged = this.$refs.clienteForm.v$.cliente.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.$router.push(path);
        return;
      }
      this.$router.push({ path: path });
    },
    async crearCliente(datos) {
      const data = await this.crear(datos);
      if (data) {
        this.$refs.clienteForm.resetFormulario();
        this.$router.push({ path: `/clientes/${data.Id}` });
        this.$toast.add(this.$alerts.clienteCreado);
      }
    },
  },
};
</script>
