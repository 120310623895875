import axios from "./config";

export const crearSede = async (sede, clienteId) => {
  const { data, status } = await axios
    .post(`/cliente/${clienteId}/sede`, sede)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarSede = async (sede) => {
  const { data, status } = await axios
    .put(`/cliente/${sede.IdCliente}/sede/${sede.Id}`, sede)
    .catch((e) => e.response);
  return { data, status };
};
