<template>
  <loader-component v-if="isLoading" />
  <toast-element :baseZIndex="1000" position="bottom-right" />
  <router-view />
</template>

<script>
import { mapState } from "vuex";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  components: { LoaderComponent },
  computed: {
    ...mapState(["isLoading"]),
  },
  beforeMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const alturaFila = 39;
      this.$store.commit("setEsMovil", width <= 767);

      // Calcular registros por pantalla para las tablas
      const espacioUsado = (270 / height) * 100;
      let total = parseInt((height * (100 - espacioUsado)) / 100 / alturaFila);
      if (total <= 0) total = 1;
      this.$store.commit("setPorPagina", total);

      // Calcular la altura fija de las tablas por pantalla
      const alturaTabla = 90 + total * alturaFila;
      this.$store.commit("setAlturaTabla", alturaTabla);
    },
  },
};
</script>
