<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de usuarios</h3>
    </template>
    <template #end>
      <input-search @search="(data) => listarHandler({ buscar: data })" />
    </template>
  </toolbar-element>
  <!-- Tabla -->
  <table-data-lazy
    :options="lista.data"
    :totalRecords="lista.total"
    :columns="columns"
    :sortable="true"
    :empty="emptyMsg"
    :buttonEdit="false"
    @generatePage="listarHandler"
  />
</template>

<script>
import { mapState } from "vuex";
import { listarUsuarios } from "../services/soporte-cmms";
import TableDataLazy from "@/components/TableDataLazy.vue";
import InputSearch from "@/components/InputSearch.vue";

export default {
  components: { TableDataLazy, InputSearch },
  data() {
    return {
      lista: { data: [], total: 0 },
      columns: [],
      emptyMsg: "No se encontraron resultados...",
      tableFilters: {},
    };
  },
  computed: {
    ...mapState(["porPagina"]),
  },
  created() {
    this.columns = [
      { field: "IdT", header: "Código" },
      { field: "RazonSocial", header: "Razón social" },
      { field: "NombreComercial", header: "Nombre comercial" },
      { field: "Identificacion", header: "Identificación" },
      { field: "Login", header: "Login" },
      { field: "Rol", header: "Rol" },
      { field: "Nombre", header: "Nombre" },
      { field: "Email", header: "Email" },
      { field: "Telefono", header: "Teléfono" },
      { field: "Estado", header: "Estado" },
    ];
  },
  async mounted() {
    await this.listarHandler();
  },
  methods: {
    async listarHandler(params = {}) {
      if (!params?.rows) params.rows = this.porPagina;
      this.tableFilters = { ...this.tableFilters, ...params };
      this.$store.commit("setIsLoading", true);
      const { data, status } = await listarUsuarios(this.tableFilters);
      this.$store.commit("setIsLoading", false);
      if (status == 200) this.lista = data;
      else this.$alertStatus(status, data);
    },
  },
};
</script>
