<template>
  <div>
    <div class="w-full flex align-items-center justify-content-between">
      <label
        class="font-bold text-sm ml-2"
        :class="{ 'input-required': required }"
      >
        {{ label }}
      </label>
      <button-element
        v-if="showCreateButton"
        :label="labelButton"
        icon="pi pi-plus"
        class="p-button-success p-1 text-sm button-pformfield"
        @click="$emit('clickCreate')"
      />
    </div>
    <div class="flex mt-1">
      <button-element
        v-if="showEditButton"
        icon="pi pi-eye"
        class="p-button-success"
        @click="$emit('clickEdit')"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    showCreateButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    labelButton: {
      type: String,
      default: "Nuevo",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.button-pformfield {
  .pi.pi-plus {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
