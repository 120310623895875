import axios from "@/services/config";
import store from "@/store/index"

const codigoPais = () => store.state.gpais.dbPais.IdT

export const listarProducto = async (params) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/productos`, { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarProducto = async (id) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/productos/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearProducto = async (producto) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/productos`, producto)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarProducto = async (producto) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .put(`/garantto/${gpais}/productos/${producto.Id}`, producto)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarProducto = async (idProducto) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .delete(`/garantto/${gpais}/productos/${idProducto}`)
    .catch((e) => e.response);
  return { data, status };
};
