import { createApp } from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Configuracion personalizada
import { install as axios } from "./services/config.js";
import globalComponents from "./plugins/globalComponents";
import primevue from "./plugins/primevue";
import mensajes from "./plugins/mensajes";
import common from "./plugins/common";
import alert from "./helpers/alert";
import asset from "./plugins/asset"

// Estilos CSS
import "./sass/app.scss";
// Soporte PWA
import "./registerServiceWorker";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(globalComponents);
app.use(primevue);
app.use(common);
app.use(alert);
app.use(mensajes);
app.use(axios);
app.use(asset);
app.mount("#app");
