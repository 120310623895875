<template>
  <mensaje-invalidos class="m-0 mt-3" v-if="v$.form.$error" />
  <div class="formgrid grid mt-5">
    <div class="col-6">
      <p-float-label label="No. Compañias" required>
        <input-number
          v-model="form.companias"
          :class="{
            'p-invalid': v$?.form?.companias?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6">
      <p-float-label label="No. Usuarios" required>
        <input-number
          v-model="form.usuarios"
          :class="{
            'p-invalid': v$?.form?.usuarios?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-12">
      <h3 class="mb-2">Módulos</h3>
    </div>
    <div class="col-4 mt-1 field-checkbox">
      <check-box v-model="form.modulos.ventas" id="ventas" :binary="true" />
      <label for="ventas">Ventas</label>
    </div>
    <div class="col-4 mt-1 field-checkbox">
      <check-box v-model="form.modulos.compras" id="compras" :binary="true" />
      <label for="compras">Compras e inventario</label>
    </div>
    <div class="col-4 mt-1 field-checkbox">
      <check-box
        v-model="form.modulos.contabilidad"
        id="contabilidad"
        :binary="true"
      />
      <label for="contabilidad">Contabilidad</label>
    </div>
    <div class="col-12">
      <h3 class="mb-2">Documentos electrónicos</h3>
    </div>
    <div class="col-12 mt-3">
      <p-float-label label="Facturas de Ventas">
        <input-number
          v-model="form.documentos.facturasVentas"
          :useGrouping="false"
        />
      </p-float-label>
    </div>
    <div class="col-12 mt-4">
      <p-float-label label="Notas Crédito y débito">
        <input-number
          v-model="form.documentos.notasCredito"
          :useGrouping="false"
        />
      </p-float-label>
    </div>
    <div class="col-12 mt-4">
      <p-float-label label="Documentos soporte">
        <input-number
          v-model="form.documentos.documentoSoporte"
          :useGrouping="false"
        />
      </p-float-label>
    </div>
    <div class="col-12 mt-4">
      <p-float-label label="Empleados">
        <input-number
          v-model="form.documentos.empleados"
          :useGrouping="false"
        />
      </p-float-label>
    </div>
    <div class="col-12 mt-4">
      <p-float-label label="Acuses a factura de compra">
        <input-number v-model="form.documentos.acuses" :useGrouping="false" />
      </p-float-label>
    </div>
    <div class="col-6">
      <p class="mb-2">Total por mes: {{ documentoTotalMes }} / Mes</p>
    </div>
    <div class="col-6">
      <p class="mb-2">Total por año: {{ documentoTotalAnio }} / Año</p>
    </div>
    <div class="col-12">
      <h3 class="mb-2">Datos cliente</h3>
    </div>
    <div class="col-12 mt-3">
      <p-float-label label="Nombre cliente" required>
        <input-text
          v-model="form.datosCliente.nombre"
          type="text"
          :class="{
            'p-invalid': v$?.form?.datosCliente?.nombre?.$error,
          }"
        />
      </p-float-label>
    </div>
    <div class="col-6 mt-4">
      <p-float-label label="Email">
        <input-text v-model="form.datosCliente.email" type="text" />
      </p-float-label>
    </div>
    <div class="col-6 mt-4">
      <p-float-label label="Télefono">
        <input-text v-model="form.datosCliente.telefono" type="text" />
      </p-float-label>
    </div>
    <div class="col-12 mt-5">
      <p-float-label label="Comentario">
        <text-area v-model="form.datosCliente.comentarios" rows="4" />
      </p-float-label>
    </div>
  </div>
  <div class="col-12 flex justify-content-end">
    <button-element label="Cotizar" class="w-50" @click="cotizar" />
  </div>
</template>

<script>
import { alertStatus } from "@/helpers/alertStatus";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  emits: ["mostrarResultado"],
  props: {
    cotizacion: Object,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      form: {
        companias: null,
        usuarios: null,
        modulos: {
          ventas: false,
          compras: false,
          contabilidad: false,
        },
        documentos: {
          facturasVentas: null,
          notasCredito: null,
          documentoSoporte: null,
          empleados: null,
          acuses: null,
        },
        datosCliente: {
          nombre: "",
          email: "",
          telefono: "",
          comentarios: "",
        },
      },
      result: 0,
    };
  },
  mounted() {
    if (Object.keys(this.cotizacion ?? {}).length > 0)
      this.form = this.cotizacion;
  },
  computed: {
    documentoTotalMes() {
      return (
        this.form.documentos.facturasVentas +
        this.form.documentos.notasCredito +
        this.form.documentos.documentoSoporte +
        this.form.documentos.empleados +
        this.form.documentos.acuses
      );
    },
    documentoTotalAnio() {
      return this.documentoTotalMes * 12;
    },
  },
  methods: {
    async cotizar() {
      if (!(await this.v$.$validate())) return;
      const body = { ...this.form, documentosMes: this.documentoTotalMes };

      this.$store.commit("setIsLoading", true);
      const { status, data } = await this.$http
        .post("/cotizador", body)
        .catch((e) => e.response);
      this.$store.commit("setIsLoading", false);
      if (status == 200) {
        this.$emit("mostrarResultado", { ...body, ...data });
      } else {
        alertStatus(status, data);
      }
    },
  },
  validations() {
    return {
      form: {
        companias: { required },
        usuarios: { required },
        datosCliente: {
          nombre: { required },
        },
      },
    };
  },
  watch: {
    datosCotizacion: {
      handler(valor) {
        if (valor) {
          this.form = { ...valor };
        }
      },
      immediate: true,
    },
  },
};
</script>
