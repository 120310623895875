import store from "../store";

/**
 * Get the full path of the asset
 * @param {String} url 
 * @returns {String}
 */
export const asset = (url) => {
  if (!url) return null;
  const baseUrl = store?.state?.auth?.asset_url ?? location.origin;
  if (url.charAt(0) === '/') url = url.slice(1);
  return `${baseUrl}/${url}`
}

export default {
  install: (app) => {
    app.config.globalProperties.$asset = asset;
  }
}
