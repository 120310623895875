import {
  crearPais,
  actualizarPais,
  eliminarPais,
  listarPaises,
} from "@/services/paises";

const state = {
  data: [],
};

const getters = {
  getLista: (state) => state.data,
};

const mutations = {
  setData: (state, data) => (state.data = data),
  setCreated: (state, pais) => state.data.push(pais),
  setUpdated: (state, pais) => {
    const index = state.data.findIndex((e) => e.Id == pais.Id);
    state.data[index] = pais;
  },
  setDeleted: (state, idPais) => {
    const index = state.data.findIndex((e) => e.Id == idPais);
    state.data.splice(index, 1);
  },
};

const actions = {
  async listar({ commit }) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await listarPaises();
    commit("setIsLoading", false, { root: true });
    status == 200 ? commit("setData", data) : this.$alertStatus(status, data);
  },
  async crear({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await crearPais(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 201
      ? commit("setCreated", data)
      : this.$alertStatus(status, data);

    return status;
  },
  async actualizar({ commit }, datos) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await actualizarPais(datos);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 200
      ? commit("setUpdated", data)
      : this.$alertStatus(status, data);

    return status;
  },
  async eliminar({ commit }, idPais) {
    commit("setIsLoading", true, { root: true });
    const { data, status } = await eliminarPais(idPais);
    commit("setIsLoading", false, {
      root: true,
    });
    status == 204
      ? commit("setDeleted", idPais)
      : this.$alertStatus(status, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
