import { swal } from "./swalConfig";
import { alertStatus } from "./alertStatus";
import * as alerts from "./alertMessages";

const install = (app) => {
  const { globalProperties } = app.config;

  app.config.globalProperties.$swal = swal;
  app.config.globalProperties.$store.$swal = swal;

  app.config.globalProperties.$alerts = alerts;
  app.config.globalProperties.$alertStatus = alertStatus;
  app.config.globalProperties.$store.$alerts = alerts;
  app.config.globalProperties.$store.$alertStatus = alertStatus;

  app.config.globalProperties.$store.$toast = globalProperties.$toast;
};
export default install;
