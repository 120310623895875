import axios from "@/services/config";
import store from "@/store/index"

const codigoPais = () => store.state.gpais.dbPais.IdT

export const listarMarca = async (params) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/marcas`, { params })
    .catch((e) => e.response);
  return { data, status };
};

export const buscarMarca = async (id) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .get(`/garantto/${gpais}/marcas/${id}`)
    .catch((e) => e.response);
  return { data, status };
};

export const crearMarca = async (marca) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/marcas`, marca)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarMarca = async (marca) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .post(`/garantto/${gpais}/marcas/${marca.get('Id')}`, marca)
    .catch((e) => e.response);
  return { data, status };
};

export const eliminarMarca = async (idMarca) => {
  const gpais = codigoPais()
  const { data, status } = await axios
    .delete(`/garantto/${gpais}/marcas/${idMarca}`)
    .catch((e) => e.response);
  return { data, status };
};
