<template>
  <div class="p-inputgroup">
    <span class="p-float-label">
      <input-text
        :modelValue="modelValue"
        :disabled="disabled"
        :class="{
          'p-invalid': error,
        }"
        @input="(e) => $emit('update:modelValue', e.target?.value || null)"
      />
      <label :class="{ 'input-required': required }">{{ label }}</label>
    </span>
    <button-element
      icon="pi pi-whatsapp"
      class="p-button-success"
      @click="clickAnchorTag"
      :disabled="error || !modelValue"
    />
    <a
      :href="`${whatsappLink}`"
      ref="anchorTag"
      target="_blank"
      class="hidden"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "WhatsApp",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validator: Boolean,
    modelValue: String,
    required: Boolean,
  },
  computed: {
    error() {
      return this.validator;
    },
    whatsappLink() {
      return `https://wa.me/${this.modelValue}`;
    },
  },
  methods: {
    clickAnchorTag() {
      this.$refs.anchorTag.click();
    },
  },
};
</script>
