<template>
  <toolbar-element class="submenu">
    <template #start>
      <h3 class="m-0">Lista de clientes</h3>
    </template>
    <template #end>
      <input-search @search="(data) => listarHandler({ buscar: data })" />
      <button-element icon="pi pi-file-excel" @click="generarExcel" />
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        class="ml-2"
        :disabled="!userCanEdit"
        @click="$router.push({ path: '/clientes/crear' })"
      />
    </template>
  </toolbar-element>
  <table-data-lazy
    :options="lista"
    :columns="columns"
    :empty="emptyMsg"
    :total-records="total"
    :sortable="true"
    @editar="abrirEditar"
    @generate-page="listarHandler"
  >
    <template #IdTEstado="{ data, value }">
      <span
        class="color-badge"
        :style="{ backgroundColor: data.ColorEstado }"
        >{{ value }}</span
      >
    </template>
  </table-data-lazy>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { generarExcelCliente } from "@/services/clientes.js";
// Componentes
import TableDataLazy from "@/components/TableDataLazy.vue";
import InputSearch from "@/components/InputSearch.vue";

export default {
  components: { TableDataLazy, InputSearch },
  data() {
    return {
      clientes: [],
      columns: [],
      emptyMsg: "No se encontraron clientes...",
      tableFilters: {},
    };
  },
  computed: {
    ...mapState(["esMovil"]),
    ...mapGetters({
      lista: "clientes/getLista",
      total: "clientes/getTotal",
      userCanEdit: "auth/userCanEdit",
    }),
  },
  created() {
    // Columnas de la tabla
    this.columns = [
      { field: "Nit", header: "NIT" },
      { field: "RazonSocial", header: "Razón social" },
      { field: "Alias", header: "Alias" },
      { field: "NombreComercial", header: "Nombre comercial" },
      { field: "Productos", header: "Productos" },
      { field: "BaseDatos", header: "Base de datos" },
      { field: "Celular", header: "Celular" },
      { field: "Categoria", header: "Categoría" },
      { field: "EmailFacturacion", header: "Email" },
      { field: "IdTEstado", header: "Estado" },
    ];
  },
  async mounted() {
    await this.listar();
  },
  methods: {
    ...mapActions({
      listar: "clientes/listar",
    }),
    abrirEditar(data) {
      this.$router.push({ path: `/clientes/${data.Id}` });
    },
    listarHandler(params = {}) {
      this.tableFilters = { ...this.tableFilters, ...params };
      this.listar(this.tableFilters);
    },
    async generarExcel() {
      this.$store.commit("setIsLoading", true);
      const { data, status, headers } = await generarExcelCliente();
      this.$store.commit("setIsLoading", false);
      if (status == 200) {
        //Nombre del archivo enviado
        const headerLine = headers["content-disposition"];
        const name = headerLine?.split("filename=")?.pop() || "file.xlsx";

        //Descargar archivo
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Alerta archivo generado
        this.$toast.add(this.$alerts.archivoGenerado);
      } else this.$alertStatus(status, data);
    },
  },
};
</script>
