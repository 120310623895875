<template>
  <!-- Submenu -->
  <toolbar-element class="submenu">
    <template #start>
      <button-element
        icon="pi pi-arrow-left"
        @click="navegar('/clientes')"
      ></button-element>
    </template>
    <template #end>
      <button-element
        icon="pi pi-plus"
        label="Nuevo registro"
        :disabled="!userCanEdit"
        @click="navegar('/clientes/crear')"
      ></button-element>
    </template>
  </toolbar-element>
  <!-- Tabs -->
  <div class="max-content">
    <tab-view>
      <tab-panel header="Cliente">
        <div class="p-2 standard-shadow">
          <cliente-form
            ref="clienteForm"
            :datos="cliente"
            @form-submit="actualizarCliente"
          />
        </div>
      </tab-panel>
      <tab-panel header="Contactos">
        <cliente-contacto-tab />
      </tab-panel>
      <tab-panel header="Sedes">
        <sede-tab
          :sedes="sedes"
          @abrir-crear="abrirSedeModal = true"
          @abrir-editar="preEditarSede"
        />
      </tab-panel>
      <tab-panel header="Contratos">
        <contrato-tab
          :contratos="contratos"
          @abrir-crear="abrirContratoModal = true"
          @abrir-editar="preEditarContrato"
        />
      </tab-panel>
    </tab-view>
  </div>
  <!-- Modales -->
  <sede-modal
    ref="sedeModal"
    v-model:datos="sede"
    v-model="abrirSedeModal"
    @crear-sede="crearSede"
    @editar-sede="actualizarSede"
  />
  <contrato-modal
    ref="contratoModal"
    v-model:datos="contrato"
    v-model="abrirContratoModal"
    @crear-contrato="crearContrato"
    @editar-contrato="actualizarContrato"
    @duplicar-contrato="duplicarContrato"
  />
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import ClienteForm from "@/components/cliente/ClienteForm.vue";
import ClienteContactoTab from "@/components/cliente/ClienteContactoTab.vue";
import SedeTab from "@/components/sede/SedeTab.vue";
import SedeModal from "@/components/sede/SedeModal.vue";
import ContratoTab from "@/components/contrato/ContratoTab.vue";
import ContratoModal from "@/components/contrato/ContratoModal.vue";

export default {
  components: {
    ClienteForm,
    ClienteContactoTab,
    SedeTab,
    SedeModal,
    ContratoTab,
    ContratoModal,
  },
  data() {
    return {
      cliente: {},
      sedes: [],
      sede: {},
      contratos: [],
      contrato: {},
      abrirSedeModal: false,
      abrirContratoModal: false,
    };
  },
  computed: {
    ...mapGetters({
      listaEstados: "cbx/getEstados1y2",
      listaRegimen: "cbx/getRegimen",
      listaTipoDocumento: "cbx/getTipoDocumento",
      listaPais: "cbx/getPais",
      listaCategoria: "cbx/getCategoria",
      userCanEdit: "auth/userCanEdit",
    }),
    ...mapState("cbx", ["isLoading"]),
  },
  async created() {
    const { contactos, sedes, contratos, ...cliente } =
      await this.clienteBuscar(this.$route.params.id);
    if (cliente) {
      this.cliente = cliente;
      this.sedes = sedes;
      this.contratos = contratos;
      this.setContactos(contactos || []);
    }
  },
  methods: {
    ...mapActions({
      clienteBuscar: "clientes/buscar",
      clienteActualizar: "clientes/actualizar",
      sedeCrear: "sede/crear",
      sedeActualizar: "sede/actualizar",
      contratoCrear: "contratos/crear",
      contratoActualizar: "contratos/actualizar",
      contratoDuplicar: "contratos/duplicar",
      contratoBuscar: "contratos/buscar",
    }),
    ...mapMutations({
      setContactos: "clientes/setContactos",
    }),
    async navegar(path) {
      const formHasChanged = this.$refs.clienteForm.v$.cliente.$anyDirty;
      if (formHasChanged) {
        const res = await this.$alerts.descartarCambios();
        if (res.isConfirmed) this.$router.push(path);
        return;
      }
      this.$router.push({ path: path });
    },
    async actualizarCliente(datos) {
      const payload = { datos: datos, id: this.cliente.Id };
      const data = await this.clienteActualizar(payload);
      if (data) {
        this.cliente = data;
        this.$refs.clienteForm.resetFormulario();
        this.$toast.add(this.$alerts.clienteActualizado);
      }
    },
    async crearSede(datos) {
      const payload = { datos: datos, clienteId: this.cliente.Id };
      const data = await this.sedeCrear(payload);
      if (data) {
        this.sedes.push(data);
        this.$refs.sedeModal.resetModal();
        this.$toast.add(this.$alerts.sedeCreado);
      }
    },
    async actualizarSede(datos) {
      // eslint-disable-next-line
      const { RazonSocial, IdTEstado, IdTPais, IdTCiudad, ...sede } = datos;
      const data = await this.sedeActualizar(sede);
      if (data) {
        this.sedes = this.sedes.map((element) =>
          element.Id == data.Id ? data : element
        );
        this.$refs.sedeModal.resetModal();
        this.$toast.add(this.$alerts.sedeActualizado);
      }
    },
    async crearContrato(contrato) {
      const payload = { datos: contrato, clienteId: this.cliente.Id };
      const data = await this.contratoCrear(payload);
      if (data) {
        this.contratos.push(data);
        this.$refs.contratoModal.resetModal();
        this.$toast.add(this.$alerts.contratoCreado);
      }
    },
    async actualizarContrato(contrato) {
      const payload = { datos: contrato, clienteId: this.cliente.Id };
      const data = await this.contratoActualizar(payload);
      if (data) {
        this.contratos = this.contratos.map((element) =>
          element.Id == data.Id ? data : element
        );
        this.$refs.contratoModal.resetModal();
        this.$toast.add(this.$alerts.contratoActualizado);
      }
    },
    async duplicarContrato(contratoId) {
      const data = await this.contratoDuplicar(contratoId);
      if (data) {
        this.contratos.push(data);
        this.$refs.contratoModal.resetModal();
        this.$toast.add(this.$alerts.contratoDuplicado);
      }
    },
    preEditarSede(data) {
      this.sede = data;
      this.abrirSedeModal = true;
    },
    async preEditarContrato(data) {
      this.contrato = await this.contratoBuscar(data.Id);
      if (this.contrato) this.abrirContratoModal = true;
    },
  },
  watch: {
    sedes: {
      handler() {
        this.$store.commit("clientes/setSedes", this.sedes);
      },
      deep: true,
    },
  },
};
</script>
