import axios from "./config";

export const getCbx = async (name, params = {}) => {
  const { data, status } = await axios
    .get(`/cbx/${name}`, { params })
    .catch((e) => e.response);
  return { data, status };
};

export const getCbxPorCliente = async (name, clienteId) => {
  const { data, status } = await axios
    .get(`/cbx/cliente/${clienteId}/${name}`)
    .catch((e) => e.response);
  return { data, status };
};
