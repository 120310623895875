import axios from "./config";

export const listarAtributo = async (params) => {
  const { data, status } = await axios
    .get("/atributo", { params })
    .catch((e) => e.response);
  return { data, status };
};

export const crearAtributo = async (atributo) => {
  const { data, status } = await axios
    .post("/atributo", atributo)
    .catch((e) => e.response);
  return { data, status };
};

export const actualizarAtributo = async (atributo) => {
  const { data, status } = await axios
    .put(`/atributo/${atributo.Id}`, atributo)
    .catch((e) => e.response);
  return { data, status };
};
